import { Col, Container, ListGroup, ListGroupItem, Row } from "reactstrap";
import { FluentButton } from "../../ui/fluent/fluent-component-wrapper";
import styles from './submit-schedule.module.scss'
import { app } from "newTeamsjs";
import * as microsoftTeams from "@microsoft/teams-js";
import * as mgt from "@microsoft/mgt-element";
import { useSelector } from "react-redux";
import { getAuthState } from "../../../store/auth-slice";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import {appInsights, reactPlugin } from "../../../config/app-insights";
import "../../../i18n/translation";
import { useTranslation } from "react-i18next";
import { getTabViewState } from "../../../store/tab-view-slice";
import { getBookWorkspaceState } from "../../../store/book-workspace-slice";
import { People } from "@microsoft/mgt-react";
import moment from "moment";
import { Constant, DateFormats } from "../../../utils/constant";
import { AttendanceTypes } from "../../../models/UserPresenceResponse";
import { htmlSanitizer } from "../../../utils/htmlsanitizer";
import { SeverityLevel } from "@microsoft/applicationinsights-web";



const DeskBooking = (props: any) => {
    const { t, i18n } = useTranslation();
    const tabViewState = useSelector(getTabViewState);
    const authState = useSelector(getAuthState);
    const bookWorkspaceState = useSelector(getBookWorkspaceState);
    const moment = require('moment/min/moment-with-locales');

    const closeTaskModule = async () => {
        await app.initialize();
        if(bookWorkspaceState.replyToId !== undefined){
            redirect();
        }
    }
    const redirect = () => {
        microsoftTeams.initialize();
        appInsights.trackTrace({ message: `${DeskBooking.name}/${redirect.name}: Task module submit request for user ${authState.adminApi?.userId}`, severityLevel: SeverityLevel.Information, properties:{UserId:authState.adminApi?.userId, ReplyToId:bookWorkspaceState.replyToId}});
        const _data = JSON.stringify({ "SSOToken": authState.ssoToken, "GraphTokenOBO": authState.graphToken, "UserCurrentLoginState": mgt.ProviderState.SignedIn.toString(), "ReplyId": bookWorkspaceState.replyToId, "InitialTimestamp": bookWorkspaceState.initialTimestamp })
        const response = { taskComponentName: "ScheduleInitialize", data: _data };
        microsoftTeams.tasks.submitTask(response);
        microsoftTeams.tasks.submitTask();
    }


    const renderUserPresence = () => {
        const userPresence = tabViewState?.userPresence?.data?.dateWiseUserPresence;
        const finalMembers = bookWorkspaceState.selectedDates.map(d =>{
            const members = userPresence?.find(x => x.date === d)?.members?.
            filter(_ => _.userId !== authState.adminApi?.userId && _.attendanceType === AttendanceTypes.PresentInOffice)?.map(s => s.email);
            return {date: moment(d), members: members !== undefined ? members : [] };
        });
        return finalMembers;   
    }

    return (
        <div id="submitScheduleView" className={`ent-teams-app ${styles['page-submit-schedule']} ${styles['set-status-view']}`}>
            <header>
                <Container fluid>
                    <Row>
                       { 
                         authState.graphTokenErrorCode===Constant.ConsentNotGrantedCode && 
                         <Col xs="12" className={`${styles["submit-shedule-comp-top-des"]} ${styles["submit-shedule-bkws"]}`}>
                                {t("TAB_43")}<a href="https://www.condecosoftware.com/help/condeco-admin-user/topic/teams-bot" target="_blank">{t("TAB_44")}</a>
                         </Col>
                       }
                    </Row>
                    <Row>
                        <Col xs="12" id="submitScheduleTopMsg">                  
                            <div className={`${styles['booking-confirmation-hdr-sec']}`}> {/* Render this element baseed on condition needed. When it will render, remove 'hidden' attribute, and all siblings elements of it from dom */}
                                <div className={`${styles['submit-shedule-comp-title']}`}>
                                    {t('TAB_18')}
                                </div>
                                <div className={`${styles['desks-name']}`}>{bookWorkspaceState.selectedDeskName}</div>
                                <div className={`${styles['floor-name']} font-weight-bold`}>{bookWorkspaceState.selectedFloorName}</div>
                                <div className={`${styles['location-name']} font-weight-bold`}>{htmlSanitizer(bookWorkspaceState.selectedLocName)}</div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </header>

            <main>
                <Container fluid>
                    <Row>
                        <Col xs="12">
                            <div className={`${styles['days']}`}>
                                <ListGroup flush className={`${styles['day-list']} ${styles['day-check-list']}`}>
                                    {renderUserPresence().map((d, i) => (
                                        <ListGroupItem className="ps-0 pe-0 bg-transparent border-bottom-0" key={i}>
                                            <div className="align-items-center d-flex">
                                                <div className="w-50">
                                                    <div className={`${styles['day-text-wrap']} d-flex align-items-center`}>
                                                        <img src="/assets/images/svg/checked.svg" className={`flex-shrink-0 ${styles['img-check-mark']}`} alt="" aria-hidden="true" />
                                                        <div className="text-truncate">
                                                            <span className="font-weight-bold me-1"> {d.date.locale(i18n.language).format(DateFormats.DateOnly)}</span> <span> {d.date.locale(i18n.language).format(DateFormats.MonthOnly)}  {d.date.locale(i18n.language).format(DateFormats.DayOnly)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-50 ps-2">
                                                    <People className={`${styles['people-ui']}`} userIds={d.members} showMax={Constant.ShowMax}></People>
                                                </div>
                                            </div>
                                        </ListGroupItem>
                                    ))
                                    }
                                </ListGroup>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </main>

            <footer>
                    <div className={`${styles['form-action']} text-end`} >
                        <FluentButton appearance="outline" onClick={async () => await closeTaskModule()}>{t('TAB_14')}</FluentButton>
                    </div>     
            </footer>
        </div>
    );
}
export default withAITracking(reactPlugin, DeskBooking, "desk-book");