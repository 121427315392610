import { Person, PersonCardInteraction, ViewType } from '@microsoft/mgt-react';
import BookWorkspace from './book-workspace';
import { useSearchParams } from 'react-router-dom';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../../../config/app-insights';
import { bookWorkspaceActions } from '../../../store/book-workspace-slice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store/store';

interface PropData {
    setSubmitDetails: Function;
    setCanBookWS: Function;
}
const SubmitSchedule = (props: PropData) => {
    const dispatch = useDispatch<AppDispatch>();
    const [searchParams] = useSearchParams();
    const replyToId = searchParams.get('replyToId') !== null ? searchParams.get('replyToId') : null;
    const initialTimestamp = searchParams.get('initialTimestamp') !== null ? searchParams.get('initialTimestamp') : null;
    dispatch(bookWorkspaceActions.setReplyToId(replyToId));
    dispatch(bookWorkspaceActions.setInitialTimestamp(initialTimestamp));
    /*
    * get props here from login component if logged-In user details are required
    */
    return (
        <BookWorkspace setSubmitDetails={props.setSubmitDetails} setCanBookWS={props.setCanBookWS}/>
    );
}

export default withAITracking(reactPlugin, SubmitSchedule, "submit-schedule");