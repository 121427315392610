import { Col, Container, ListGroup, ListGroupItem, Row } from "reactstrap";
import { FluentButton } from "../../ui/fluent/fluent-component-wrapper";
import styles from './submit-schedule.module.scss'
import { People } from '@microsoft/mgt-react';
import LocationComboBox from '../../ui/fluent/location-combobox'
import { useEffect, useRef, useState } from "react";
import DataService from "../../../services/DataService";
import { Constant, DateFormats, WorkSpaceType } from "../../../utils/constant";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SetPresence } from "../../../models/SetUserPresence";
import { useSelector } from "react-redux";
import { getAuthState } from "../../../store/auth-slice";
import { fetchUserPresenceAsync, getTabViewState } from "../../../store/tab-view-slice";
import Loader from "../../ui/fluent/loader";
import useCountryDropdown from "../../../hooks/useCountryDropdown";
import { AttendanceTypes } from "../../../models/UserPresenceResponse";
import moment, { Moment } from "moment";
import * as momentTZ from "moment-timezone";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { getGraphState } from "../../../store/graph-slice";
import { UserPresenceRequest } from "../../../models/api/types/UserPresence/UserPresenceRequest";
import { StateIndicator } from "../../../models/shared/Enums/StateIndicator";
import { appInsights, reactPlugin } from "../../../config/app-insights";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import  "../../../i18n/translation";
import { useTranslation } from "react-i18next";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

interface PropData {
    setSubmitDetails: Function;
    setCanBookWS: Function;
}

const SetStatus = (props: PropData) => {
    const { t, i18n } = useTranslation();
    const moment = require('moment/min/moment-with-locales');
    const tabViewState = useSelector(getTabViewState);
    const authState = useSelector(getAuthState);
    const graphClientState = useSelector(getGraphState);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const dropdownData = useCountryDropdown();
    const globalSwitch = useRef<boolean>();
    const userId = useRef<number>(0);
    const [geodata, setDefaultLocation] = useState<number>();
    const [countryDropdownData, setcountryDropdownData] = useState<any[]>([]);
    const [isPersonalSpaceSet, SetIsPersonalSpaceSet] = useState<boolean>(false);
    const [searchParams] = useSearchParams();
    const selectedDate = useRef<Moment>(moment(searchParams.get("date")));
    const [isButtonDisabled, SetButtonDisabled] = useState<boolean>(false);

    const [selectedLocationId, setLocationId] = useState<number>();
    let selectedLocation: number;
    let locName = countryDropdownData.filter((d: any) => {
        return d.id.toString() === selectedLocationId?.toString();
    });
    let selectedLocationName = locName[0]?.name;
    

    useEffect(() => {
        if (dropdownData.current.length > 0 && Object.keys(tabViewState.locations).length > 0) {
            if (Object.keys(tabViewState.userPresence).length === 0 && authState.adminApi?.userId
                && tabViewState.usePresenceStatus === StateIndicator.Idle 
                && (authState.graphTokenErrorCode===Constant.ConsentNotGrantedCode|| graphClientState.frequentCollaboratorStatus === StateIndicator.Success || graphClientState.frequentCollaboratorStatus === StateIndicator.Failed)
                && (authState.graphTokenErrorCode===Constant.ConsentNotGrantedCode|| graphClientState.membersStatus === StateIndicator.Success || graphClientState.membersStatus === StateIndicator.Failed)) {
                    fetchPresenceWithMembersAndCollaborators(selectedDate.current);
            }
            if (Object.keys(tabViewState.userPresence).length > 0 && tabViewState.usePresenceStatus !== StateIndicator.Loading && !selectedLocationId) {
                retrieveData();
            }
        }
    }, [tabViewState.locationStatus, authState.adminApi?.userId, tabViewState.usePresenceStatus,graphClientState.frequentCollaboratorStatus, graphClientState.membersStatus, authState?.adminApiTokenStatus,authState.graphTokenErrorCode]);


      const fetchPresenceWithMembersAndCollaborators = async (start: Moment) => {
        const req = await prepareUserPresenceReq(start);
        dispatch(fetchUserPresenceAsync(req));
        }

        const locationTimeZoneId = (value: any) => {
            let locTimzeZone = '';
            if (Number(value) > 0) {
    
                let locations = tabViewState?.locations?.data?.countries.flatMap(c => c.regions).flatMap(l => l.locations)
                if (locations !== undefined && locations.length > 0) {
                    let location = locations.filter(x => x.id == value);
                    if (location !== undefined && location.length > 0) {
                        locTimzeZone = location[0].timeZoneId;
                    }
                }
            }
            return locTimzeZone;
        }

    const prepareUserPresenceReq = (start: Moment, locationId?: number) => {
        let graphMemberEmails = graphClientState?.members?.length > 0 ? graphClientState?.members?.map(x => x.email ?? "") : [];
        const req: UserPresenceRequest = {
            userId: authState.adminApi?.userId ?? 0,
            inOfficeData: true,
            locationId: locationId !== undefined ? locationId : undefined,
            dates: [start.format(DateFormats.FullDate)],
            memberEmails: graphMemberEmails?.length > 0 ? graphMemberEmails :
                (graphClientState?.frequentCollaborators?.length > 0
                    ? graphClientState.frequentCollaborators
                    : [])
        }
        appInsights.trackTrace({ message: "Preparing user presence request", severityLevel: SeverityLevel.Information, properties: { UserId: authState.adminApi?.userId, Request: JSON.stringify(req) } });
        return req;
    }

    const bookWS = async () => {
        let dateString: string = selectedDate.current.format(DateFormats.FullDate);
        appInsights.trackEvent({ name: `${SetStatus.name}/${bookWS.name}: Booking a workspace`, properties: { TenantId: authState.tenantId, UserId: authState.adminApi?.userId, Date: dateString, LocationName: selectedLocationName } });
        navigate(`/bookWSAvailability?date=${dateString}&locationId=${selectedLocationId}&locationName=${selectedLocationName}`);
    }

    const submitStatus = async () => {
        let canBookWS = isPersonalSpaceSet; //Condition 1
        if (isPersonalSpaceSet) {
            canBookWS = canBookingDeskFlag(selectedLocationId !== undefined ? selectedLocationId : 0); // Condition 2
        }

        if (canBookWS && !globalSwitch.current && selectedLocationSelfCertification(selectedLocationId !== undefined ? selectedLocationId : 0)) { // Condition 3
            canBookWS = false;
        }
        //If any one of the above mentioned three condition return false, the Book Workspace button on Submitted Schedule screen will be hidden.
        let attDetails = { Date: selectedDate.current.format(DateFormats.FullDate), AttendanceType: AttendanceTypes.PresentInOffice.toString() }
        let payLoad: SetPresence = { userID: userId.current, AttendanceDetails: [attDetails], locationId: selectedLocationId !== undefined ? selectedLocationId : 0 };        
        appInsights.trackEvent({ name: `${SetStatus.name}/${submitStatus.name}: Submitting status`, properties: { TenantId: authState.tenantId, UserId: authState.adminApi?.userId, Payload: JSON.stringify(payLoad) } });
        const response = await DataService.setUserPresence(payLoad);
        if (response.status === 200 || response.status === 201) {
            
            let setDetails = [] as any[];
            let userSelectedDates = [] as any[];
            const hasBookedDesk = tabViewState.userPresence.data.dateWiseUserPresence.find(x => x.date === selectedDate.current.format(DateFormats.FullDate))!.members.filter(mem => mem.userId === userId.current && mem.workspaceName !== undefined && mem.workspaceName.length > 0).length > 0;
            userSelectedDates.push({ presenceDate: selectedDate.current.format(DateFormats.FullDate), usersList: getUserEmails(selectedDate.current.format(DateFormats.FullDate)), hasBookedDesk: hasBookedDesk });
            props.setSubmitDetails(setDetails = ([{ setDay: 'single', selectedLocationID: selectedLocationId, selectedLocation: selectedLocationName, submitDate: userSelectedDates, isLocCertified: selectedLocationSelfCertification(selectedLocationId !== undefined ? selectedLocationId : 0) }]));
            props.setCanBookWS(canBookWS);
            navigate('/submittedStatus');
        }
        else{            
            appInsights.trackException({ exception:{ message: response.statusText, name: `${SetStatus.name}/${submitStatus.name}: Error/Failure` }, severityLevel: SeverityLevel.Error, properties: { TenantId: authState.tenantId, UserId: authState.adminApi?.userId, Payload: JSON.stringify(payLoad) } });
        }
    };

    //getUserEmails data // To Do - To move to common function
    const getUserEmails = (date: string) => {
        appInsights.trackTrace({ message: `${SetStatus.name}/${getUserEmails.name}: fetching members email`, severityLevel: SeverityLevel.Information, properties: { TenantId: authState.tenantId, UserId: authState.adminApi?.userId, Date: selectedDate.current } });        
        let userEmailNew: string[] = [];
        let data: any[] = tabViewState?.userPresence?.data?.dateWiseUserPresence?.filter(p => p.date === date);
        if (data !== null && data !== undefined && data.length > 0) {
            let membersData = data[0].members.filter((q: { locationId: number; attendanceType: number; userId: number }) => q.userId != userId.current);
            if (membersData.length > 0) {
                membersData.map((md: any) => {
                    userEmailNew.push(md.email);
                });
            }
            return userEmailNew;
        }
        return userEmailNew;
    }

    const onLocationChange = (value: number) => {
        selectedLocation = value;
        setLocationId(value);
        checkIsPastDate();
        dispatch(fetchUserPresenceAsync(prepareUserPresenceReq(selectedDate.current, value)));        
        appInsights.trackEvent({ name: `${SetStatus.name}/${onLocationChange.name}: Changing Location`, properties: { TenantId: authState.tenantId, UserId: authState.adminApi?.userId, LocationId: value } });
    }

    const canBookingDeskFlag = (value: number): boolean => {
        let flag = false;
        tabViewState.locations.data.countries.map((rgn) => {
            let regions = rgn.regions;
            regions.map((loc) => {
                let locations = loc.locations.filter(x => x.id == value)
                locations.map((wrkspc) => {
                    let workspace = wrkspc.workspaceTypes.filter(w => w.resourceId == WorkSpaceType.DeskResourceID)
                    workspace.map(gp => {
                        let gpdata = gp.groups.filter(g => g.makeBooking);
                        flag = gpdata.length > 0 ? true : false;
                    });
                })
            });
        });
        return flag;
    }

    const selectedLocationSelfCertification = (value: number): boolean => {
        let flag = false;
        let location = dropdownData.current.filter(loc => (loc.id == value && loc.certificate === true));
        flag = location.length > 0 ? true : false;
        return flag;
    }

    const retrieveData = async () => {        
        appInsights.trackTrace({ message: `${SetStatus.name}/${retrieveData.name}: Initializing join the office`, severityLevel: SeverityLevel.Information, properties: { TenantId: authState.tenantId, UserId: authState.adminApi?.userId, Date: selectedDate.current } });
        let pSpace = tabViewState?.locations?.data?.personalSpaceDefaults?.locationId;
        let mSpace = tabViewState?.locations?.data?.meetingSpaceDefaults?.locationId;
        setDefaultLocation(pSpace > 0 ? pSpace : mSpace > 0 ? mSpace : 0);

        let previousLocation = Number(searchParams.get("locationId"));

        selectedLocation = previousLocation > 0 ? previousLocation :
            pSpace > 0 ? pSpace :
                mSpace > 0 ? mSpace :
                    0;

        setLocationId(selectedLocation);
        SetIsPersonalSpaceSet(pSpace > 0 ? true : false)
        setDefaultLocation(selectedLocation);

        let currentDay = selectedDate.current.format(DateFormats.FullDate) === moment().format(DateFormats.FullDate);
        globalSwitch.current = tabViewState.appSettings.mandateBookingToSetInOffice;

        checkIsPastDate();

        if (currentDay && globalSwitch.current) {
            dropdownData.current = dropdownData.current.filter((locData: any) => {
                return locData.certificate === false;
            });
        }
        setcountryDropdownData(dropdownData.current.slice());
        userId.current = authState.adminApi?.userId ?? 0;
    };

    const checkIsPastDate = ()  =>{
        let timeZoneId = locationTimeZoneId(selectedLocation);
        let timeZoneCurrDate = momentTZ.tz(timeZoneId).startOf("day").format(DateFormats.FullDate);
        let IsPastDate = (selectedDate.current.format(DateFormats.FullDate) < timeZoneCurrDate ) ? true : false;
        SetButtonDisabled(IsPastDate);
    }


    return (
        <>
            {countryDropdownData.length === 0 ? (<Loader />) : (
                <div id="submitScheduleView" className={`ent-teams-app ${styles['page-submit-schedule']} ${styles['set-status-view']}`}>
                    <header>
                        <Container fluid className="px-0">
                          <Row>
                             { 
                              authState.graphTokenErrorCode===Constant.ConsentNotGrantedCode && 
                                <Col xs="12" className={`${styles["submit-shedule-comp-top-des"]} ${styles["submit-shedule-bkws"]}`}>
                                    {t("TAB_43")}<a href="https://www.condecosoftware.com/help/condeco-admin-user/topic/teams-bot" target="_blank">{t("TAB_44")}</a>
                                </Col>
                             }
                            </Row>
                            <Row>
                                <Col xs="12" id="submitScheduleTopMsg" className={`${styles['submit-shedule-comp-title']}`} dangerouslySetInnerHTML={{ __html: t('TAB_9').replace("#DATE#", `<b>${moment(selectedDate.current).locale(i18n.language).format(DateFormats.DisplayDate)}</b>`) }}>
                                </Col>
                            </Row>
                        </Container>
                    </header>

                    <main>
                        <Container fluid className="px-0">
                            <Row>
                                <Col xs="12" className={`${styles['location-block']}`}>
                                    <LocationComboBox dropDownData={countryDropdownData} defaultSelection={selectedLocationId} onLocationChange={onLocationChange} labelText={t("TAB_13")} />
                                </Col>

                                <Col xs="12">
                                    <div className={`${styles['days']}`}>
                                        <div className="font-weight-bold">{t('TAB_10')}</div>
                                        <ListGroup flush className={`${styles['day-list']} ${styles['day-list-has-nochkbx']}`}>
                                            <ListGroupItem className="ps-0 pe-0 bg-transparent border-bottom-0">
                                                <div className="align-items-center d-flex">
                                                    <div className="w-50 text-truncate">
                                                        <span className="font-weight-bold"> {moment(selectedDate.current).locale(i18n.language).format(DateFormats.DateOnly)} </span> {moment(selectedDate.current).locale(i18n.language).format(DateFormats.MonthOnly) + ' ' + moment(selectedDate.current).format(DateFormats.DayOnly)}
                                                    </div>
                                                    <div className="w-50 ps-2">
                                                        <People className={`${styles['people-ui']}`} userIds={getUserEmails(selectedDate.current.format(DateFormats.FullDate))} showMax={Constant.ShowMax}></People>
                                                    </div> 
                                                </div>
                                            </ListGroupItem>
                                        </ListGroup>
                                    </div>
                                </Col>                             
                                <Col xs="12" id="submitScheduleBottomMsg" className={`${styles['submitScheduleBottomMsg']}`}>
                                    {globalSwitch.current ?
                                        <span>                                           
                                            {t('TAB_27')}
                                        </span>
                                        : <span>{t('TAB_26')}</span>
                                    }
                                </Col>      
                                {/* This code will be uncommented once book a workspace journey becomes functional
                                {true ?
                                    <Col xs="12" id="submitScheduleBottomMsg" className={`${styles['submitScheduleBottomMsg']}`}>
                                        Changing your location will also cancel your personal workspace
                                        <span className="font-weight-bold"> [Location] </span> at
                                        <span className="font-weight-bold"> [Workspace] </span>
                                    </Col> : ''
                                } */}
                            </Row>
                        </Container>
                    </main>

                    <footer>
                            <div className={`${styles['form-action']} text-end`}>
                            {
                                    globalSwitch.current && tabViewState.appSettings?.deskBookingAllowed ?
                                    <FluentButton appearance="outline" disabled={isButtonDisabled} onClick={bookWS}>{t('TAB_11')}</FluentButton> :
                                    <FluentButton appearance="outline" disabled={isButtonDisabled} onClick={submitStatus}>{t('TAB_19')}</FluentButton>
                                
                               
                            }
                        </div>
                        
                    </footer>
                </div>)}
        </>)
}

export default withAITracking(reactPlugin, SetStatus, "set-status");