export interface BookDesk{
    userId: number,
    deskId: number,
    dateList: DateList[],
    isUserSelfCertified: true,
}

export interface DateList {
    date: string;
    bookingType: number;
}

export interface BookDeskResponse {
    bookingId: number;
}

export enum DeskBookingType {
    AMOnly = 0,
    PMOnly = 1,
    WholeDay = 2
}