import * as React from 'react';
import './App.scss';
import SSOLogin from './components/ssologin/sso-login';

interface IProps {
  children: React.ReactNode;
}

const App = (props: IProps) => {
  return (
    <>
      {/* <LoginSSO>
     </LoginSSO> */}
     <SSOLogin>
      {props.children}
     </SSOLogin>
    </>
  );
}

export default App;
