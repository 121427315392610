import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { app, authentication } from "newTeamsjs";
import { RootState } from "./store";
import { ValidateSSOResponse } from "../models/ValidateSSOResponse";
import AuthServices from "../services/AuthServices";
import { StateIndicator } from "../models/shared/Enums/StateIndicator";
import { TokenErrorResponse } from "../models/api/types/AdminApi/TokenErrorResponse";
import { appInsights } from "../config/app-insights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

export const getAuthTokenAsync = createAsyncThunk(
  "auth/getAuthToken",
  async (_, thunkAPI) => {
    try {
      app.initialize();
      const token = await authentication.getAuthToken();
      if (token !== undefined && token !== "") {
        try {
          const ctx = await thunkAPI.dispatch(getContextAsync()).unwrap();
          if (ctx === undefined) {
            return thunkAPI.rejectWithValue("failed to load context");
          }
        } catch (error) {
          return thunkAPI.rejectWithValue(error);
        }
      }
      return token;
    } catch (error :any) {
       appInsights.trackException({ exception:{ message: error, name: `${AuthSlice.name}/${getAuthTokenAsync.name}: Exception` }, severityLevel: SeverityLevel.Error});
       return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getContextAsync = createAsyncThunk("auth/getContext", async () => {
  app.initialize();
  return await app.getContext();
});

export const getAdminApiTokenAsync = createAsyncThunk(
  "auth/getAdminApiToken",
  async (req:any, thunkAPI) => {
    try {
        return await AuthServices.getAdminApiToken(req.ssoToken, req.email);
    } catch (error:any) {
        appInsights.trackException({ exception:{ message: error, name: `${AuthSlice.name}/${getAdminApiTokenAsync.name}: Exception` }, severityLevel: SeverityLevel.Error,properties:{UserId:req.email}});
        throw thunkAPI.rejectWithValue(error);
    }
    
  }
);

export const getGraphTokenAsync = createAsyncThunk("auth/getGraphToken", async (req: any, thunkAPI) => {
    try {
        const graphToken = await AuthServices.getGraphToken(req.ssoToken);
        return graphToken;
    } catch (error: any) {
       appInsights.trackException({ exception:{ message: error, name: `${AuthSlice.name}/${getGraphTokenAsync.name}: Exception` }, severityLevel: SeverityLevel.Error,properties:{UserId:req.email}});
       throw thunkAPI.rejectWithValue(error);
    }
});


export interface AuthType {
  isLoggedIn: boolean;
  ssoToken?: string;
  tenantId?: string;
  email: string | undefined;
  status: StateIndicator;
  adminApi: ValidateSSOResponse | undefined;
  graphToken: string;
  graphTokenErrorCode:number;
  userGuid?: string;
  error: boolean;
  userState: boolean;
  context: app.Context;
  contextStatus: StateIndicator;
  adminApiTokenStatus: StateIndicator;
  adminApiTokenError: TokenErrorResponse | undefined;
}

const initialState: AuthType = {
  isLoggedIn: false,
  ssoToken: "",
  tenantId: undefined,
  email: "",
  status: StateIndicator.Idle,
  adminApi: undefined,
  graphToken: "",
  graphTokenErrorCode:0,
  userGuid: "",
  error: false,
  userState: false,
  context: {} as app.Context,
  contextStatus: StateIndicator.Idle,
  adminApiTokenStatus: StateIndicator.Idle,
  adminApiTokenError: undefined
};

const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoginState(state, action){
        state.isLoggedIn = action.payload;
    },
    setError(state, action){
        state.error = action.payload;
    },
    setUserState(state, action){
        state.userState = action.payload;
    },
    setAdminApiErrorCode(state, action){
      state.adminApiTokenError = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAuthTokenAsync.pending, (state) => {
        state.status = StateIndicator.Loading;
      })
      .addCase(getAuthTokenAsync.fulfilled, (state, action) => {
        state.status = StateIndicator.Success;
        state.ssoToken = action.payload;
      })
      .addCase(getAuthTokenAsync.rejected, (state) => {
        state.status = StateIndicator.Failed;
      })
      .addCase(getContextAsync.pending, (state) => {
        state.contextStatus = StateIndicator.Loading;
      })
      .addCase(getContextAsync.fulfilled, (state, action) => {
        state.contextStatus = StateIndicator.Success;
        state.email = action.payload.user?.userPrincipalName;
        state.tenantId = action.payload.user?.tenant?.id;
        state.userGuid = action.payload.user?.id;
        state.context = action.payload;
      })
      .addCase(getContextAsync.rejected, (state) => {
        state.contextStatus = StateIndicator.Failed;
      })
      .addCase(getAdminApiTokenAsync.pending, (state) => {
        state.adminApiTokenStatus = StateIndicator.Loading;
      })
      .addCase(getAdminApiTokenAsync.fulfilled, (state, action) => {
        if(action.payload !== undefined){
          state.adminApiTokenStatus = StateIndicator.Success;
            state.adminApi = action.payload;
            //state.isLoggedIn = true;
        } else {
          state.adminApiTokenStatus = StateIndicator.Failed;
            state.adminApi = {} as ValidateSSOResponse;
            //state.isLoggedIn = false;
        }
      })
      .addCase(getAdminApiTokenAsync.rejected, (state, action) => {
        state.adminApiTokenStatus = StateIndicator.Failed;
        state.adminApiTokenError = action.payload as TokenErrorResponse;
        
        //state.isLoggedIn = false;
      })
      .addCase(getGraphTokenAsync.pending, state => {
        state.status = StateIndicator.Loading;
      })
      .addCase(getGraphTokenAsync.fulfilled, (state, action) => {
        state.graphTokenErrorCode=0;
        if(action.payload != null && action.payload !== undefined){
            state.status = StateIndicator.Success;
            state.graphToken = action.payload;
        } else {
            state.status = StateIndicator.Failed;
            state.graphToken = "";
        }
      })
      .addCase(getGraphTokenAsync.rejected, (state,action) => {
        state.status = StateIndicator.Failed;
        state.graphTokenErrorCode=action.payload as number;
      })
  },
});

export const authActions = AuthSlice.actions;
export const getAuthState = (state: RootState) => state.auth;
export default AuthSlice;
