export class Constant {
  public static FunctionAppBaseURL = process.env.REACT_APP_FUNCTION_APP_URL;
  public static SSOToken: string = "sso-token";
  public static AdminApiToken: string = "token";
  public static AdminApiTokenKey = "token-key";
  public static AdminApiUrl = "api-url";
  public static ShowMax: number = 10;
  public static DefualtLocationText: string = "Select";
  public static FrequentCollaboratorCount: number = Number(process.env.REACT_APP_FrequentCollaboratorCount);
  public static ApiRetryCount: number = 3;
  public static ApiRetryBackOffInterval: number = 1500;
  public static ConsentNotGrantedCode:number=403
}

export class StorageKeys {
   public static AdminApiInfo = "cdco-adminapiinfo";
}

export class GraphConstants {
  public static GraphClientAction='graph/initializeGraphClient/fulfilled';
  public static PeopleUrlEndpoint = "/me/people/?$top=" +Constant.FrequentCollaboratorCount+ "&$filter=personType/class eq 'Person' and personType/subclass eq 'OrganizationUser'&$Select=scoredEmailAddresses";
  public static TeamMembersUrlEndpoint="/teams/{0}/members";
  public static GroupChatMembersUrlEndpoint="chats/{0}/members"
}

export class DateFormats {
  public static FullDate = 'yyyy-MM-DD';
  public static DateOnly = 'dddd'
  public static DayOnly = 'D';
  public static MonthOnly = 'MMM';
  public static YearOnly = 'YYYY';
  public static DisplayDate='DD MMM yyy';
  public static TwelveHourTime = 'h:mm A';
}

export class TimeConstant {
  public static NextWeekValue = 8;
}

export class WorkSpaceType {
    public static DeskResourceID: number = 128;
}

export class ConfigTabConstants {
    public static TabName = "Condeco";
    public static EntityId = "condecoTab";
}

export class TaskModuleDimensions {
    public static Height = 600;
    public static Width = 600;
}