import i18n from 'i18next';
import enUS from './lang.en-US.json';
import deDE from './lang.de-DE.json'
import frFR from './lang.fr-FR.json'
import { initReactI18next } from 'react-i18next';
import { app } from "newTeamsjs";

export const resources = {
    en: {
      translation:enUS
    },
    fr:{
      translation:frFR
    },
    de:{
      translation:deDE
    }
  } as const;

  i18n.use(initReactI18next).init({
    lng:"en",
    supportedLngs: ["en", "fr","de"],
    fallbackLng: "en",
    resources,
  });
 
  export const setLanguage=()=>{
     app.initialize();
     app.getContext().then(ctx=>{
         switch(ctx.app.locale.toLowerCase()){
           case "de-de" :
             i18n.changeLanguage("de");
             break;
           case "fr-fr":
             i18n.changeLanguage("fr");
             break;
           default:
             i18n.changeLanguage("en");
        }
      });   
  }


  