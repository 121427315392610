import { Col, Container, ListGroup, ListGroupItem, Row } from "reactstrap";
import { FluentButton } from "../../ui/fluent/fluent-component-wrapper";
import { People } from "@microsoft/mgt-react";
import styles from './submit-schedule.module.scss'
import { app } from "newTeamsjs";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Constant } from "../../../utils/constant";
import * as microsoftTeams from "@microsoft/teams-js";
import * as mgt from "@microsoft/mgt-element";
import { useDispatch, useSelector } from "react-redux";
import { getAuthState } from "../../../store/auth-slice";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import {appInsights, reactPlugin } from "../../../config/app-insights";
import "../../../i18n/translation";
import { useTranslation } from "react-i18next";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { getTabViewState } from "../../../store/tab-view-slice";
import { htmlSanitizer } from "../../../utils/htmlsanitizer";
import { bookWorkspaceActions, getBookWorkspaceState } from "../../../store/book-workspace-slice";
import { AppDispatch } from "../../../store/store";
import { AttendanceTypes } from "../../../models/UserPresenceResponse";

interface PropData {
    submitDetail: any;
    canBookWS: boolean;
}

const SubmittedSchedule = (props: PropData) => {
    const authState = useSelector(getAuthState);
    const tabViewState = useSelector(getTabViewState);
    const { t, i18n } = useTranslation();
    const moment = require('moment/min/moment-with-locales');
    const navigate = useNavigate();
    const bookWorkspaceState = useSelector(getBookWorkspaceState);
    const dispatch = useDispatch<AppDispatch>();
    const {
        ssoToken,
        graphToken,
        graphTokenErrorCode
    } = useSelector(getAuthState);
    const closeTaskModule = async () => {
        await app.initialize();
        redirect();
    }
    const redirect = () => {
        microsoftTeams.initialize();
        const _data = JSON.stringify({ "SSOToken": ssoToken, "GraphTokenOBO": graphToken, "UserCurrentLoginState": mgt.ProviderState.SignedIn.toString(), "ReplyId": bookWorkspaceState.replyToId, "InitialTimestamp":bookWorkspaceState.initialTimestamp })
        const response = { taskComponentName: "ScheduleInitialize", data: _data };
        appInsights.trackTrace({ message: `${SubmittedSchedule.name}/${redirect.name} : Submitting request to bot`, severityLevel: SeverityLevel.Information, properties: { Request: JSON.stringify(response) } });
        microsoftTeams.tasks.submitTask(response);
        microsoftTeams.tasks.submitTask();
    }
    const bookDesk = async () => {
        let dates = props.submitDetail[0].submitDate.filter((x:any) => !x.hasBookedDesk).map((d: { presenceDate: any }) => d.presenceDate);
        appInsights.trackEvent({ name: `${SubmittedSchedule.name}/${bookDesk.name}: Booking a workspace availability`, properties: { Datea: dates, LocationName: props.submitDetail[0].selectedLocation, ReplyToId: bookWorkspaceState.replyToId, InitialTimestamp: bookWorkspaceState.initialTimestamp } });
        dispatch(bookWorkspaceActions.setSelectedDates(dates));
        navigate(`/bookWSAvailability?date=${dates}&locationId=${props.submitDetail[0].selectedLocationID}&locationName=${props.submitDetail[0].selectedLocation}&replyId=${bookWorkspaceState.replyToId}&initialTimestamp=${bookWorkspaceState.initialTimestamp}`);
    }

    let selectedDates: any[] = [];
    let unSelectedDates: any[] = [];
    if(props.submitDetail[0]?.submitDate !== undefined && props.submitDetail[0]?.submitDate !== null && props.submitDetail[0]?.submitDate.length > 0){
        selectedDates = props.submitDetail[0]?.submitDate;
    }
    if(props.submitDetail[0]?.unSelectedDates !== undefined && props.submitDetail[0]?.unSelectedDates !== null && props.submitDetail[0]?.unSelectedDates.length > 0){
        unSelectedDates = props.submitDetail[0]?.unSelectedDates;
    }
    const multiDates = [] as any[];
	
    if(selectedDates !== undefined && selectedDates !== null && selectedDates.length > 0) {
        selectedDates.forEach((data) => {
        multiDates.push({ selectedDay: moment(data.presenceDate).locale(i18n.language).format("dddd"), selectedDate: moment(data.presenceDate).locale(i18n.language).format("MMM"), selectedMonth: moment(data.presenceDate).locale(i18n.language).format("D"), usersPresent: data.usersList })
    })
    } else if(unSelectedDates !== undefined && unSelectedDates !== null && unSelectedDates.length > 0) {
        unSelectedDates.forEach((data) => {
            multiDates.push({ selectedDay: moment(data.presenceDate).locale(i18n.language).format("dddd"), selectedDate: moment(data.presenceDate).locale(i18n.language).format("MMM"), selectedMonth: moment(data.presenceDate).locale(i18n.language).format("D"), usersPresent: data.usersList })
        });
    }
    if (selectedDates?.length == 0 && unSelectedDates?.length == 0) {
        if (bookWorkspaceState.bookWspceSelectedLocId !== 0 && bookWorkspaceState.replyToId !== undefined && bookWorkspaceState.replyToId !== '') {
            if (tabViewState?.userPresence?.data?.dateWiseUserPresence?.length > 0) {
                tabViewState.userPresence.data?.dateWiseUserPresence?.map((data: any) => {
                    let members: any[] = data.members;
                    let usersPresent = [] as any[];
                    let userLocationPresenece: any[] = members.filter((q: { locationId: number; userId: number; attendanceType: number; }) => q.locationId == bookWorkspaceState.bookWspceSelectedLocId && q.userId === authState?.adminApi?.userId && q.attendanceType.toString() === AttendanceTypes.PresentInOffice.toString());
                    if (members !== undefined && members.length > 0) {
                        members.forEach((data) => {
                            if (data.userId !== authState?.adminApi?.userId && Number(data.locationId) === bookWorkspaceState.bookWspceSelectedLocId && data.attendanceType.toString() === AttendanceTypes.PresentInOffice.toString()) {
                                usersPresent.push(data.email);
                            }
                        })
                    }
                    if (userLocationPresenece?.length > 0) {
                        multiDates.push({ selectedDay: moment(data.date).locale(i18n.language).format("dddd"), selectedDate: moment(data.date).locale(i18n.language).format("MMM"), selectedMonth: moment(data.date).locale(i18n.language).format("D"), usersPresent: usersPresent })
                    }
                })
            }
        }
    }
    return (
        <div id="submitScheduleView" className={`ent-teams-app ${styles['page-submit-schedule']} ${styles['set-status-view']}`}>
            <header>
                <Container fluid>
                <Row>
                       { 
                         graphTokenErrorCode===Constant.ConsentNotGrantedCode && 
                            <Col xs="12" className={`${styles["submit-shedule-comp-top-des"]} ${styles["submit-shedule-bkws"]}`}>
                             {t("TAB_43")}<a href="https://www.condecosoftware.com/help/condeco-admin-user/topic/teams-bot" target="_blank">{t("TAB_44")}</a>
                            </Col>
                       }
                    </Row>
                    <Row>
                        <Col xs="12" id="submitScheduleTopMsg">                  
                            <div className={`${styles['submit-shedule-comp-top-des']}`}>
                            { selectedDates?.length > 0 || (multiDates?.length > 0 && unSelectedDates?.length === 0) ?                                  
                                    <span> 
                                       <span dangerouslySetInnerHTML={{__html: t('TAB_28').replace("#location#",htmlSanitizer(props.submitDetail[0].selectedLocation))}}></span> 
                                    </span> :
                                    <span>
                                       <span dangerouslySetInnerHTML={{__html: t('TAB_46')}}></span>
                                    </span>
                                }
                            </div>
                            <div className={`${styles['booking-confirmation-hdr-sec']}`} hidden> {/* Render this element baseed on condition needed. When it will render, remove 'hidden' attribute, and all siblings elements of it from dom */}
                                <div className={`${styles['submit-shedule-comp-title']}`}>
                                    {t('TAB_18')}
                                </div>
                                <div className={`${styles['desks-name']}`}>Desk M15</div>
                                <div className={`${styles['floor-name']} font-weight-bold`}>Floor 8</div>
                                <div className={`${styles['location-name']} font-weight-bold`}>Exchange Tower</div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </header>

            <main>
                <Container fluid>
                    <Row>
                        <Col xs="12">
                            <div className={`${styles['days']}`}>
                                <ListGroup flush className={`${styles['day-list']} ${styles['day-check-list']}`}>
                                    {multiDates.map((data) => (
                                        <ListGroupItem className="ps-0 pe-0 bg-transparent border-bottom-0">
                                            <div className="align-items-center d-flex">
                                                <div className="w-50">
                                                    <div className={`${styles['day-text-wrap']} d-flex align-items-center`}>
                                                        <img src="/assets/images/svg/checked.svg" className={`flex-shrink-0 ${styles['img-check-mark']}`} alt="" aria-hidden="true" />
                                                        <div className="text-truncate">
                                                            <span className="font-weight-bold me-1"> {data.selectedDay + ' '}</span> <span> {data.selectedDate + ' '}{data.selectedMonth}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-50 ps-2">
                                                    <People className={`${styles['people-ui']}`} userIds={data.usersPresent} showMax={Constant.ShowMax}></People>
                                                </div>
                                            </div>
                                        </ListGroupItem>
                                    ))
                                    }
                                </ListGroup>
                            </div>
                        </Col>
                        {props.canBookWS && selectedDates !== undefined && selectedDates !== null && selectedDates.length > 0 && tabViewState.appSettings?.deskBookingAllowed ?
                            <Col xs="12" id="submitScheduleBottomMsg" className={`${styles['submitScheduleBottomMsg']}`}>
                                {t('TAB_29')}
                            </Col> : ''
                        }
                    </Row>
                </Container>
            </main>

            <footer>
                {props.canBookWS && selectedDates !== undefined && selectedDates !== null && selectedDates.length > 0 && tabViewState.appSettings?.deskBookingAllowed ?
                    <div className={`${styles['form-action']} text-end`}>
                        <FluentButton appearance="outline" onClick={async () => await closeTaskModule()}>{t('TAB_20')}</FluentButton>
                        {!props.submitDetail[0].submitDate.every((x:any) => x.hasBookedDesk) && <FluentButton appearance="outline" onClick={bookDesk}>{t('TAB_11')}</FluentButton>}
                    </div> :
                    <div className={`${styles['form-action']} text-end`} >
                        <FluentButton appearance="outline" onClick={async () => await closeTaskModule()}>{t('TAB_14')}</FluentButton>
                    </div>
                }
            </footer>
        </div>
    );
}

export default withAITracking(reactPlugin, SubmittedSchedule, "submitted-schedule");