import moment, { Moment } from "moment";
import React from "react";
import './cn-mini-calendar.scss'
//import Moment from "moment";

export interface IMiniCalendarLabelProps {
    startDate: Moment;
    endDate?: Moment;
}

const CnMiniCalendarLabel: React.FC<IMiniCalendarLabelProps> = (props: IMiniCalendarLabelProps) => { 
    const getFormattedDate = (momentObj: Moment) => {
        if(!momentObj) {
            return;
        }
        
        const weekDay = momentObj.format('dddd');
        const date = momentObj.format('DD');
        const month = momentObj.format('MMM');

        return (
            <>
                <strong className="cn-mini-calendar-label-bold">{ weekDay }</strong><strong className="cn-mini-calendar-label-semibold">, { date } { month }</strong>
            </>
        );
    }

    const endDateTemplate = () => {
        let retTpl = null;

        if(props.endDate) {
            retTpl = <>
                <span> - </span>
                <span>{ getFormattedDate(props.endDate) }</span>
            </>;
        }

        return retTpl;
    }

    return (
        <div className="cn-mini-calendar-label">
            <span>{ getFormattedDate(props.startDate) }</span>
            { endDateTemplate() }
        </div>
    );
};

export default CnMiniCalendarLabel;