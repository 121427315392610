import { dialog, DialogDimension, TaskModuleDimension } from "newTeamsjs";
import { useEffect, useRef, useState } from "react";
import { Col, Container, Row, Alert, Badge } from "reactstrap";
import CnMiniCalendar, {
  IMiniCalendarResponse,
} from "../../ui/cn-mini-calendar/cn-mini-calendar";
import {
  FluentAnchor,
  FluentButton,
  FluentDesignSystemProvider,
  FluentDivider,
  FluentToolbar,
} from "../../ui/fluent/fluent-component-wrapper";
import styles from "./tab-view-calendar.module.scss";
import {
  AttendanceTypes,
  MemberData,
  Members,
} from "../../../models/UserPresenceResponse";
import moment, { Moment } from "moment";
import { useSelector } from "react-redux";
import {
  fetchUserPresenceAsync,
  getTabViewState,
} from "../../../store/tab-view-slice";
import { getAuthState } from "../../../store/auth-slice";
import Loader from "../../ui/fluent/loader";
import TabViewWeekDayColumn from "./tab-view-weekday-col";
import _ from "underscore";
import { getGraphState, graphActions } from "../../../store/graph-slice";
import { UserPresenceRequest } from "../../../models/api/types/UserPresence/UserPresenceRequest";
import Extensions from "../../../utils/extensions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { StateIndicator } from "../../../models/shared/Enums/StateIndicator";
import { TimeConstant, DateFormats, TaskModuleDimensions, Constant } from "../../../utils/constant";
import { LocationApiResponse } from "../../../models/Locations";
import { appInsights, reactPlugin } from "../../../config/app-insights";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import  "../../../i18n/translation";
import { useTranslation } from "react-i18next";

enum weekDays {
  Saturday = 6,
  Sunday = 7
};

interface TabProps { }

const TabViewCalendar = (props: TabProps) => {
    const { t } = useTranslation();
    const moment = require('moment/min/moment-with-locales');
  const renderRef = useRef<number>(1);
  const tabViewState = useSelector(getTabViewState);
  const authState = useSelector(getAuthState);
  const graphClientState = useSelector(getGraphState);
  const [showPersonalSpace, setShowPersonalSpace] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const [showBookWorkspaceBtn, setshowBookWorkspaceBtn] =
    useState<Boolean>(false);
    const [isDefaultLocationAvailable, SetIsDefaultLocationAvailable] = useState<Boolean>(true)
    const globalSwitch = useRef<boolean>();
  const [consentVisible, setConsentVisible] = useState(true);
  const onDismissConsent = () => setConsentVisible(false);
    
  useEffect(() => {
    renderRef.current++;
    if (tabViewState.locationStatus === StateIndicator.Success && Object.keys(tabViewState?.locations).length > 0) {
      getDefaultLocation();
    }
  }, [tabViewState?.locationStatus]);

  useEffect(() => {
    if (tabViewState.appSettingsStatus === StateIndicator.Success 
       && tabViewState?.appSettings &&
      Object.keys(tabViewState?.appSettings).length > 0
    ) {
        const execute = async () => {
            globalSwitch.current = tabViewState.appSettings.mandateBookingToSetInOffice;
            await determineWorkspaceBtnState();
        };
      execute();
    }
  }, [tabViewState?.appSettingsStatus]);

  useEffect(() => {
    const execute = async () => {
      if (
        ((authState.graphTokenErrorCode===Constant.ConsentNotGrantedCode||graphClientState.frequentCollaboratorStatus === StateIndicator.Success || graphClientState.frequentCollaboratorStatus === StateIndicator.Failed)
        && authState?.adminApi?.userId && 
        (authState.graphTokenErrorCode===Constant.ConsentNotGrantedCode||graphClientState.membersStatus === StateIndicator.Success || graphClientState.membersStatus === StateIndicator.Failed))
      ) {
        fetchPresenceWithMembersAndCollaborators();
        dispatch(graphActions.resetCollaboratorStatus(StateIndicator.Idle));
        dispatch(graphActions.resetMembersStatus(StateIndicator.Idle));
      }
    };
    execute();
  }, [graphClientState.frequentCollaboratorStatus, graphClientState.membersStatus, authState?.adminApiTokenStatus,authState.graphTokenErrorCode]);

  useEffect(() => {
    if(tabViewState.usePresenceStatus === StateIndicator.Success){
      renderScreen();
    }
  }, [tabViewState.usePresenceStatus]);
  
  const getDefaultLocation = async () => {
    const response = tabViewState?.locations;
    const locData: LocationApiResponse = response;
    const showPersonBtn =
      Object.keys(locData.data.personalSpaceDefaults).length !== 0 &&
      locData.data.personalSpaceDefaults.locationId > 0;
    setShowPersonalSpace(showPersonBtn);

    const showMeetingBtn = Object.keys(locData.data.meetingSpaceDefaults).length !== 0 &&
      locData.data.meetingSpaceDefaults.locationId > 0;
  
    tabViewState.appSettings.mandateBookingToSetInOffice ? 
                    SetIsDefaultLocationAvailable(showPersonBtn) :
                    SetIsDefaultLocationAvailable(showPersonBtn || showMeetingBtn);    
  };

  const dateChangeHandler = (range: IMiniCalendarResponse) => {

    if (renderRef.current !== 1 && authState.adminApi?.userId) {
      fetchPresenceWithMembersAndCollaborators(range.startDate.startOf("isoWeek"));
    }
  };

  const determineWorkspaceBtnState = async () => {
    const appSettings = tabViewState.appSettings;
    const userId = authState?.adminApi?.userId;
    const userPresence = tabViewState?.userPresence?.data?.dateWiseUserPresence;
    if (userPresence !== undefined && userPresence.length > 0) {
      const key = moment().format("yyyy-MM-DD");
      const members: Members[] = userPresence
        .filter((c) => c.date === key)
        .map((f) =>
          f.members.filter(
            (g) =>
              g.userId === userId &&
              g.attendanceType === AttendanceTypes.PresentInOffice
          )
        )
        .flat();

      if (
        members != null &&
        members !== undefined &&
        members.length > 0 &&
        !appSettings?.mandateBookingToSetInOffice
      ) {
        setshowBookWorkspaceBtn(true);
      }
    }
  };

    const launchTaskModule = async (date: Moment, urlString: string, titleString: string) => {
    dialog.open({      
        url: urlString,
        size: { height: TaskModuleDimensions.Height, width: TaskModuleDimensions.Width },
        title: titleString,
    },
      () => {
        fetchPresenceWithMembersAndCollaborators(date.startOf("isoWeek"));
      });
  };

    const bookAWorkspaceHandler = async (date: Moment, locationId: number, locationName: string) => {
        appInsights.trackEvent({ name: "user booking a workspace", properties: { date, locationId } });
        let dateString: string = date.format(DateFormats.FullDate).toString();
        const url = `${process.env.REACT_APP_TAB_URL}/bookWSAvailability?date=${dateString}&locationId=${locationId}&locationName=${locationName}`;
        let title = t('TAB_11')
        await launchTaskModule(date, url, title);
    };

  const setInOfficeHandler = async (date: Moment, locationId: Number) => {
      appInsights.trackEvent({ name: "user setting in office", properties: { date, locationId } });
      const url = `${process.env.REACT_APP_TAB_URL}/setStatus?date=${date.format(DateFormats.FullDate)}&locationId=${locationId}`;
      let title = t('TAB_24')
      await launchTaskModule(date, url, title);
  };

  const fetchPresenceWithMembersAndCollaborators = async (start?: Moment) => {
    const req = await prepareUserPresenceReq(start);
    dispatch(fetchUserPresenceAsync(req));
    }
    

  const renderScreen = () => {
    if(authState.adminApiTokenError?.ErrorCode === 2 && authState.adminApiTokenError.StatusCode === 404){
      return (<div>
        <h6><strong>{t("TAB_71")}</strong></h6>
        <h6>{t("TAB_72")}</h6>
        <h6>{t("TAB_73")}</h6>
      </div>);
    }
    if(authState.adminApiTokenError?.ErrorCode === 1 && authState.adminApiTokenError.StatusCode === 404){
      return (<div>
        <h6><strong>{t("TAB_68")}</strong></h6>
        <h6>{t("TAB_69")}</h6>
        <h6>{t("TAB_70")}</h6>
      </div>);
    }
    if(!isDefaultLocationAvailable){
      return "";
    }    
    if(tabViewState.usePresenceStatus === StateIndicator.Success) {
      return renderDateWiseColumns();
    }
  }
  const renderDateWiseColumns = () => {
      const memberData = tabViewState.userPresence?.data?.dateWiseUserPresence;
      const locations = tabViewState?.locations?.data?.countries.flatMap(c => c.regions).flatMap(l => l.locations).map((loc) => {
          return loc;
      });

    return memberData.map(
      (data: MemberData, idx: number, original: MemberData[]) => {
            let loggedInUser = _.findWhere(data.members, { userId: authState?.adminApi?.userId });
            let currentDay = moment(data.date).format(DateFormats.FullDate) === moment().format(DateFormats.FullDate);
            let selfCertifiedLocation = locations?.filter(x => x.id == loggedInUser?.locationId && x.selfCertification === true);
            let wrkSpcBtnFlag = (currentDay && !globalSwitch.current && selfCertifiedLocation?.length > 0) ? true : false;
            let isPersonalSpaceSet = tabViewState?.locations?.data?.personalSpaceDefaults?.locationId > 0 ? true : false;
            let prevDayFlag = moment(data.date).isBefore(moment(), 'day');
        return (
          <Col key={idx}>
            <TabViewWeekDayColumn
              date={moment(data.date)}
              locationName={loggedInUser?.locationName!}
              locationId={loggedInUser?.locationId!}
              floor={loggedInUser?.floorName}             
              floorNumber={loggedInUser?.floorNumber}                         
              people={_.pluck(_.filter(data.members, function(p){ return p.userId !== loggedInUser?.userId; }), "email")}
              workspaceName={loggedInUser?.workspaceName}
              attendanceType={loggedInUser?.attendanceType}
              appSettings={tabViewState.appSettings}
              onBookAWorkspaceButtonClick={bookAWorkspaceHandler}
              onSetInOfficeButtonClick={setInOfficeHandler}
              workSpaceBtnFlag={wrkSpcBtnFlag}
              isPersonalDefaultAvailable={isPersonalSpaceSet}
              previousDayFlag={prevDayFlag}
            />
          </Col>
        );
      }
    );
  };

  const prepareUserPresenceReq = async (start?: Moment) => {
    let graphMemberEmails = graphClientState?.members?.length > 0 ? graphClientState?.members?.map(x => x.email ?? "") : [];
    let weekDates = isWeekend() ? moment().startOf("isoWeek").day(TimeConstant.NextWeekValue) : moment().startOf("isoWeek");
    const req: UserPresenceRequest = {
      userId: authState.adminApi?.userId ?? 0,
      inOfficeData: true,
      dates: Extensions.getDatesOfWeek(start ?? weekDates),
      memberEmails: graphMemberEmails?.length > 0 ? graphMemberEmails :
        (graphClientState?.frequentCollaborators?.length > 0
          ? graphClientState.frequentCollaborators
          : [])
    }
    return req;
  }

  const isWeekend = () => {
    let today = moment().isoWeekday()
    return today === weekDays.Saturday || today === weekDays.Sunday ? true : false;
  }

  return (
    <>

      <div className={styles.tabview_cal}>
        <FluentDesignSystemProvider>
          <FluentToolbar>
            <CnMiniCalendar
              isWeekEnd={isWeekend()}
              nextWeekNavigationLimit={5}
              onDateChange={dateChangeHandler}
            ></CnMiniCalendar>
          </FluentToolbar>
          <FluentDivider></FluentDivider>
          <FluentToolbar hidden={true}>
             {showPersonalSpace && (
              <FluentButton className={styles.tabview_cal__btnbook} appearance="stealth">
                <img
                  src="/assets/images/svg/calendar-person-white.svg"
                  alt=""
                />
               {t('TAB_1')} 
              </FluentButton>
            )} 
            {/* <FluentRadio>Book a meeting space</FluentRadio> */}
          </FluentToolbar>
        </FluentDesignSystemProvider>
        <Container className={styles.tabview__cal_schedule} fluid>
          {
            authState.graphTokenErrorCode===Constant.ConsentNotGrantedCode && 
            <Alert className={styles.admin_consent_alert} isOpen={consentVisible} toggle={onDismissConsent}>
              <span>
                {t("TAB_43")}
              </span>
              <span>
                <FluentAnchor href="https://www.condecosoftware.com/help/condeco-admin-user/topic/teams-bot"
                  appearance="neutral" target="_blank">{t("TAB_44")}</FluentAnchor>
              </span>
            </Alert>
          }
          <Row xs="1" sm="2" md="5" lg="5">
            {
              (tabViewState.usePresenceStatus === StateIndicator.Loading ||
              tabViewState.usePresenceStatus === StateIndicator.Idle)
              &&(authState.adminApiTokenStatus === StateIndicator.Idle||authState.adminApiTokenStatus === StateIndicator.Loading)? (
              <Loader />):
              (authState.adminApiTokenStatus === StateIndicator.Success || authState.adminApiTokenStatus === StateIndicator.Failed) && renderScreen()
            }
          </Row>
        </Container>        
      </div>
      <div className={styles.tabview_cal_defaultLocationMsg}>
              {!isDefaultLocationAvailable && <span className="text-center">{t('TAB_8')}</span>}
      </div>
      <div className={styles.tabview_cal__bg}></div>
    </>
  );
};

export default withAITracking(reactPlugin, TabViewCalendar, "tab-view-calendar");
