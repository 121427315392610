import * as moment from "moment-timezone";
import { Moment } from "moment-timezone";
import React from "react";
import { useTranslation } from "react-i18next";
import "../i18n/translation";

const GetDayList = (timeZoneId: string) => {
  const { t } = useTranslation();

  // Dynamically bind currentWeek or next WeekData
  const days: string[] = [
    t("TAB_49"),
    t("TAB_50"),
    t("TAB_51"),
    t("TAB_52"),
    t("TAB_53"),
    t("TAB_54"),
    t("TAB_55"),
  ];
  const months: string[] = [
    t("TAB_56"),
    t("TAB_57"),
    t("TAB_58"),
    t("TAB_59"),
    t("TAB_60"),
    t("TAB_61"),
    t("TAB_62"),
    t("TAB_63"),
    t("TAB_64"),
    t("TAB_65"),
    t("TAB_66"),
    t("TAB_67"),
  ];

  const date: Moment = moment.tz(timeZoneId).startOf("day");

  const currWeekDayNum: number = date.day(); 

  const remainingDays: number = days.length - currWeekDayNum; 
  let startDate: Moment = date.clone(),
    endDate: Moment = date.clone();

  if (currWeekDayNum < 4) {
    endDate = startDate.clone().add(remainingDays, "day"); 
  } else {
    startDate = startDate.clone().add(remainingDays, "day");
    endDate = startDate.clone().add(6, "day");
  }

  const getDaysArray = (start: Moment, end: Moment) => {
    const arr = [];
    for (let dt = start; dt <= end; dt.add(1, "day")) {
      if (dt.day() !== 0 && dt.day() !== 6) {
        arr.push(dt.clone());
      }
    }
    return arr;
  };

  const dayList: Moment[] = getDaysArray(startDate.clone(), endDate.clone());
  return {
    dayList: dayList,
    currentDate: date,
    startDate: startDate,
    days: days,
    months: months,
  };
};

export default GetDayList;
