/**
 * Q: Why are we created the wrappers for the FluentUI?
 * Ans: Because, React component name start with uppercase character and FluentUI components by default starts with lowercase letter.
 * In React, Tags which is starts from lowercase letter are the native HTML elements. 
 * And the Tags which is starts from Uppercase letter are the React Components.
 * Solution was taken from the Microsoft FluentUI Documentaions
 * https://docs.microsoft.com/en-us/fluent-ui/web-components/integrations/react
 * 
 * Q: How to use the component?
 * Ans: For example, if i want to use the FluentUI Button, we first import the button like this
 * 
 * import { FluentButton } from '...<path>../fluent-component-wrapper'
 * 
 * usage inside the react component:
 * <FluentButton>My Button</FluentButton>
 * 
 * Follow the same implentation as per MS FluentUI Docs
 * https://docs.microsoft.com/en-us/fluent-ui/web-components/components/overview
 * 
 * Just replace the Fluent UI Component UI Tags with our new Wrapper. 
 *  <fluent-button></fluent-button> tags becomes <FluentButton></FluentButton>
 * 
 */

import * as React from 'react';
import { fluentButton, fluentCard, fluentCheckbox, fluentCombobox, fluentDivider, fluentOption, fluentSelect
    , provideFluentDesignSystem, fluentRadio, fluentRadioGroup, fluentDialog
    , fluentListbox, fluentMenu, fluentProgressRing, fluentMenuItem, fluentTab
    , fluentTabs, fluentTabPanel, fluentTooltip, fluentTextArea, fluentTextField, fluentBadge, fluentDesignSystemProvider, fluentToolbar
  , fluentAnchor } from '@fluentui/web-components';
    import { provideReactWrapper, ReactWrapper } from '@microsoft/fast-react-wrapper';
    import { Checkbox } from '@microsoft/fast-foundation';
    
    const { wrap } = provideReactWrapper(React, provideFluentDesignSystem());
    
    export const FluentCombobox = wrap(fluentCombobox());
    export const FluentOption = wrap(fluentOption());
    export const FluentSelect = wrap(fluentSelect({
        indicator: `<img src="assets/images/svg/arrow-down.svg" alt="" aria-hidden="true" />`
      }));
    export const FluentButton = wrap(fluentButton());
    export const FluentCard = wrap(fluentCard());
    export const FluentCheckbox = wrap(fluentCheckbox(), {
      events: {
        onChange: 'change',
        checkedIndicator: `<img src="assets/images/svg/check-mark.svg" alt="" aria-hidden="true" />`
      },
    }) as ReactWrapper<Checkbox, {}>;
    export const FluentDivider = wrap(fluentDivider());
    export const FluentRadio = wrap(fluentRadio());
    export const FluentRadioGroup = wrap(fluentRadioGroup());
    export const FluentDialog = wrap(fluentDialog());
    export const FluentListbox = wrap(fluentListbox());
    export const FluentMenu = wrap(fluentMenu());
    export const FluentProgressRing = wrap(fluentProgressRing());
    export const FluentMenuItem = wrap(fluentMenuItem());
    export const FluentTab = wrap(fluentTab());
    export const FluentTabs = wrap(fluentTabs());
    export const FluentTabPanel = wrap(fluentTabPanel());
    export const FluentTooltip = wrap(fluentTooltip());
    export const FluentTextArea = wrap(fluentTextArea());
    export const FluentTextField = wrap(fluentTextField());
    export const FluentDesignSystemProvider = wrap(fluentDesignSystemProvider());
    export const FluentToolbar = wrap(fluentToolbar());
    export const FluentBadge = wrap(fluentBadge());
    export const FluentSpinner = wrap(fluentProgressRing());
    export const FluentAnchor = wrap(fluentAnchor());
    
    