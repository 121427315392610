import React, { useEffect, useState } from "react";
import { FluentButton } from "../fluent/fluent-component-wrapper";
import CnMiniCalendarLabel from "./cn-mini-calendar-label";
import moment, { Moment } from "moment";
import './cn-mini-calendar.scss'
import { Button } from "reactstrap";
import { getTabViewState, tabViewActions } from "../../../store/tab-view-slice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import { DateFormats } from "../../../utils/constant";
import { useTranslation } from "react-i18next";
import { TimeConstant } from "../../../utils/constant";

enum miniCalendarNavDirection {
    previousWeek = 0
    , currentWeek = 1 
    , nextWeek = 2
    , retain = 3
};

export interface IMiniCalendarResponse {
    startDate: Moment;
    endDate: Moment;
};

export interface IMiniCalendarProps {
    onDateChange: (response: IMiniCalendarResponse) => void;
    nextWeekNavigationLimit?: number;
    prevWeekNavigationLimit?: number;
    isWeekEnd: boolean;
};

const CnMiniCalendar: React.FC<IMiniCalendarProps> = (props: IMiniCalendarProps) => {
    const moment = require('moment/min/moment-with-locales');
    const { i18n } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const tabViewState = useSelector(getTabViewState);
    const [startDate, setStartDate] = useState<Moment>(moment());
    const [endDate, setEndDate] = useState<Moment>(moment());
    const [nextWeekNavLimit, setNextWeekNavLimit] = useState<Moment>(props.isWeekEnd && props.nextWeekNavigationLimit ? 
        moment().startOf("isoWeek").day(TimeConstant.NextWeekValue).add(props.nextWeekNavigationLimit, 'weeks').endOf('isoWeek').day(-2) :
        props.nextWeekNavigationLimit ?
        moment().add(props.nextWeekNavigationLimit, 'weeks').endOf('isoWeek').day(-2) :
        moment().endOf('isoWeek').day(-2));
    const [prevWeekNavLimit, setPrevWeekNavLimit] = useState<Moment>(props.isWeekEnd ? 
        moment().startOf("isoWeek").day(TimeConstant.NextWeekValue) : 
        moment());
    const [disabledNextBtn, setDisabledNextBtn] = useState(true); //Buttons initial state is disabled
    const [disabledPrevBtn, setDisabledPrevBtn] = useState(true);
    
    useEffect(() => {
        weekRangeButtonStateHandler();
        if(tabViewState.calStartDate !== undefined && tabViewState.caleEndDate !== undefined){
            navigateCalendar(miniCalendarNavDirection.retain);
        } else {
            props.isWeekEnd ? navigateCalendar(miniCalendarNavDirection.nextWeek): 
                                navigateCalendar(miniCalendarNavDirection.currentWeek);
        }                    
    }, []);

    const weekRangeButtonStateHandler = () => {
        if (props.prevWeekNavigationLimit) {
            if (props.prevWeekNavigationLimit > 0) {
                const prevWeekFirstDate = moment().subtract(props.prevWeekNavigationLimit, 'weeks').startOf('isoWeek');
                setPrevWeekNavLimit(prevWeekFirstDate);
            }

            if (props.prevWeekNavigationLimit === 0) {
                setPrevWeekNavLimit(moment().startOf('isoWeek'));
            }
        }
    }

    const calendarHandler = (weekDirection: miniCalendarNavDirection) => {
        let startRange: Moment = startDate;
        let endRange: Moment = endDate;

        switch (weekDirection) {
            case miniCalendarNavDirection.previousWeek:
                startRange.subtract(1, 'weeks').startOf('isoWeek');
                endRange.subtract(1, 'weeks').endOf('isoWeek').day(-2);
                break;
            case miniCalendarNavDirection.currentWeek:
                startRange.startOf('isoWeek');
                endRange.endOf('isoWeek').subtract(2, 'days');
                break;
            case miniCalendarNavDirection.nextWeek:
                startRange.add(1, 'weeks').startOf('isoWeek');
                endRange.add(1, 'weeks').endOf('isoWeek').day(-2);
                break;
            case miniCalendarNavDirection.retain:
                startRange = moment(tabViewState.calStartDate);
                endRange = moment(tabViewState.caleEndDate);
                break;
        }     

        if(endRange >= nextWeekNavLimit) {
            setDisabledNextBtn(true);
        } else{
            setDisabledNextBtn(false);
        }
        
        if(startRange <= prevWeekNavLimit) {
            setDisabledPrevBtn(true);
        } else{
            setDisabledPrevBtn(false);
        }

        setStartDate(startRange.clone());
        dispatch(tabViewActions.setCalStartDate(startRange.clone().format(DateFormats.FullDate)));
        setEndDate(endRange.clone());
        dispatch(tabViewActions.setCalEndDate(endRange.clone().format(DateFormats.FullDate)));
        props.onDateChange({ startDate: startRange, endDate: endRange });
    };

    const navigateCalendar = (weekDirection: miniCalendarNavDirection) => {
        calendarHandler(weekDirection);
    }
    
    return (
        <div className="cn-mini-calendar">
            <Button 
            color="light" 
            size="sm" 
                disabled={disabledPrevBtn}
            onClick={ ()=>{ navigateCalendar(miniCalendarNavDirection.previousWeek); } } 
            className="cn-mini-calendar-nav"            
            ><img src="/assets/images/svg/cheveron-28-left.svg" alt="previous date range" /></Button>
            <Button 
            color="light" 
            size="sm" 
                disabled={disabledNextBtn}
            onClick={ ()=>{ navigateCalendar(miniCalendarNavDirection.nextWeek); } } 
            className="cn-mini-calendar-nav"            
            ><img src="/assets/images/svg/cheveron-28-right.svg" alt="next date range" /></Button>
            
            {/* <FluentButton disabled={disabledPrevBtn} onClick={ ()=>{ navigateCalendar(miniCalendarNavDirection.previousWeek); } } className="cn-mini-calendar-nav" appearance="stealth"><img src="/assets/images/svg/cheveron-28-left.svg" alt="previous date range" /></FluentButton>
            <FluentButton disabled={disabledNextBtn} onClick={ ()=>{ navigateCalendar(miniCalendarNavDirection.nextWeek); } } className="cn-mini-calendar-nav" appearance="stealth"><img src="/assets/images/svg/cheveron-28-right.svg" alt="Next date range" /></FluentButton> */}
            <CnMiniCalendarLabel startDate={startDate.locale(i18n.language)} endDate={endDate.locale(i18n.language)} />
        </div>
    );
};

export default CnMiniCalendar;