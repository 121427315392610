export interface AppSettingsApiResponse{
    isError: boolean;
    data:    AppSettings;
    status:  boolean;
    message: string;
}

export interface AppSettings {
    mandateBookingToSetInOffice: boolean;
    deskBookingAllowed:  boolean;
    deskBookingPeriod: DeskBookingPeriod
}

export enum DeskBookingPeriod {
    "Halfday" = 0,
    "Fullday" = 1
}