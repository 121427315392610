import { useState, useEffect } from "react";
import _ from "underscore";
import { FluentSelect, FluentOption } from "./fluent-component-wrapper";
import  "../../../i18n/translation";
import { useTranslation } from "react-i18next";

interface CommonData {
    defaultSelection?: string | number,
    dropDownData:any[];
    onLocationChange: Function;
    labelText: string
}

const LocationComboBox = (props: CommonData) => {
    const { t } = useTranslation();
    const [defaultSelected, setDefaultSelected] = useState<string | number>();
    useEffect(() => {
        setDefaultSelected(props.defaultSelection);
    }, [props.defaultSelection]);
    const drpdownselect = (e: any) => {
        props.onLocationChange(e.target.value);
    }
    return (
        <div className="locationcombobox">
            <div className="form-label" id="locationDdlLbl">{props.labelText}</div>
            <FluentSelect id="locationDdl" className="fluent-select" aria-labelledby="locationDdlLbl" onInput={drpdownselect}>
                {
                    props.dropDownData.map((item: any) => <FluentOption selected={item.id === Number(defaultSelected)} key={item?.id} value={item?.id}>{item?.name}</FluentOption>)
                }
            </FluentSelect>
        </div>
    );
}

export default LocationComboBox;