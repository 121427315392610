import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { app } from "newTeamsjs";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as mgt from "@microsoft/mgt-element";
import { Container, Row, Col } from "reactstrap";
import { appInsights, reactPlugin } from "../../../config/app-insights";
import { getAuthState } from "../../../store/auth-slice";
import { bookWorkspaceActions, getBookWorkspaceState } from "../../../store/book-workspace-slice";
import { FluentButton } from "../../ui/fluent/fluent-component-wrapper";
import styles from "./submit-schedule.module.scss";
import { AppDispatch } from "../../../store/store";
import { StateIndicator } from "../../../models/shared/Enums/StateIndicator";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

const ErrorTask = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const authState = useSelector(getAuthState);
    const bookWorkspaceState = useSelector(getBookWorkspaceState);
    const appDispatch = useDispatch<AppDispatch>();
    const closeTaskModule = async () => {
        await app.initialize();
        if(bookWorkspaceState.replyToId !== undefined){
            redirect();
        }
    }
    const redirect = () => {
        microsoftTeams.initialize();
        appInsights.trackTrace({ message: `${ErrorTask.name}/${redirect.name}: Task module submit request for user ${authState.adminApi?.userId}`, severityLevel: SeverityLevel.Information, properties:{UserId:authState.adminApi?.userId, ReplyToId:bookWorkspaceState.replyToId}});
        const _data = JSON.stringify({ "SSOToken": authState.ssoToken, "GraphTokenOBO": authState.graphToken, "UserCurrentLoginState": mgt.ProviderState.SignedIn.toString(), "ReplyId": bookWorkspaceState.replyToId, "InitialTimestamp": bookWorkspaceState.initialTimestamp })
        const response = { taskComponentName: "ScheduleInitialize", data: _data };
        microsoftTeams.tasks.submitTask(response);
        microsoftTeams.tasks.submitTask();
    }
    const goback = () => {
        appDispatch(bookWorkspaceActions.setDeskBookingStatus(StateIndicator.Idle));
        navigate(-1);
        };
    return (
        <div id="submitScheduleView" className={`ent-teams-app ${styles['page-submit-schedule']}`}>
            <main>
                <Container fluid className="px-0">
                    <Row>
                        <Col xs="12" className={`${styles['error-task-content']}`}>
                            <div className={`${styles['error-task-content-text']}`}>
                            {t('TAB_38')}
                            </div>
                            <div className={`${styles['error-task-content-text']}`}>
                                <span>  {/* Render it when global switch OFF */}
                                {t('TAB_39')}
                                </span>
                                <span hidden> {/* Render it when global switch ON and remove "hidden" attribute */}
                                    Your office schedule has <span className="font-weight-bold">not</span> been updated.
                                </span>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </main>

            <footer>
                <div className={`${styles['form-action']} text-end`}>
                    <FluentButton appearance="outline" onClick={goback}>{t('TAB_21')}</FluentButton>
                    <FluentButton appearance="outline" onClick={async () => await closeTaskModule()}>{t('TAB_14')}</FluentButton>
                </div>
            </footer>
        </div>
    );
}

export default withAITracking(reactPlugin, ErrorTask, "error-task");