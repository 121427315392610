import React, { useEffect, useRef, useState } from "react";
import styles from "./book-workspace.module.scss";
import { Col, Container, Form, FormGroup, Label, Row } from "reactstrap";
import { FluentButton } from "../../ui/fluent/fluent-component-wrapper";
import { DateList, DeskAvailabilityRequest } from "../../../models/api/types/DeskAvailability/DeskAvailabilityRequest";
import { useSelector } from "react-redux";
import { getAuthState } from "../../../store/auth-slice";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment, { Moment } from "moment-timezone";
import * as mgt from "@microsoft/mgt-element";
import { Constant, DateFormats, WorkSpaceType } from "../../../utils/constant";
import { fetchUserPresenceAsync, getTabViewState, tabViewActions } from "../../../store/tab-view-slice";
import { StateIndicator } from "../../../models/shared/Enums/StateIndicator";
import Loader from "../../ui/fluent/loader";
import LocationComboBox from "../../ui/fluent/location-combobox";
import { useTranslation } from "react-i18next";
import { Location, WorkspaceType, Group, Floor } from "../../../models/Locations";
import { fetchDeskAvailabilityAsync, getBookWorkspaceState, bookDeskAsync, bookWorkspaceActions } from "../../../store/book-workspace-slice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/store";
import _ from "underscore";
import { BookDesk, DeskBookingType } from "../../../models/BookDesk";
import { appInsights } from "../../../config/app-insights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { DeskBookingPeriod } from "../../../models/AppSettingsApiResponse";
import { app } from "newTeamsjs";
import { htmlSanitizer } from "../../../utils/htmlsanitizer";


const BookWorkspaceAvailability: React.FC<any> = (props: any) => {
    const {t, i18n} = useTranslation();
    const authState = useSelector(getAuthState);
    const [searchParams] = useSearchParams();
    const selectedDate = useRef<string>(searchParams.get("date"));
    const selectedLocId = Number(searchParams.get("locationId"));
    const selectedLocationName = String(searchParams.get("locationName"));
    const tabViewState = useSelector(getTabViewState);
    const bookWorkspaceState = useSelector(getBookWorkspaceState);
    const appDispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const moments = require('moment/min/moment-with-locales');
    const hideBackButton = bookWorkspaceState.replyToId === "null" || bookWorkspaceState.replyToId === "undefined" || bookWorkspaceState.replyToId === "" ? true : false;

    useEffect(() => {
        if (Object.keys(tabViewState.locations).length > 0 && tabViewState.locationStatus === StateIndicator.Success 
                && bookWorkspaceState.wsTypeDrpData.length === 0) {
            retrieveData();
        }
    }, [tabViewState.locationStatus]);

    useEffect(() => {
        if (bookWorkspaceState.availableDesksStatus === StateIndicator.Success) {
            const desks = bookWorkspaceState?.desks.availableDesks;
                appDispatch(bookWorkspaceActions.setAvailableDrpData(desks?.length > 0 ? desks?.map(x => { return { id: x.deskID, name: x.deskName } }) : []));
                appDispatch(bookWorkspaceActions.setSelectedAvailable(desks?.length > 0 ? _.first(desks)?.deskID : 0));
        }
    }, [bookWorkspaceState.availableDesksStatus]);

    useEffect(() => {
        if(bookWorkspaceState.floorDrpData.length > 0 && bookWorkspaceState.grpDrpData.length > 0 && bookWorkspaceState.selectedLoc){
            appDispatch(fetchDeskAvailabilityAsync(prepareDeskAvailabilityReq()));
        }
    }, [bookWorkspaceState.selectedFloor, bookWorkspaceState.selectedGroup, bookWorkspaceState.selectedWSType, bookWorkspaceState.selectedLoc])

    useEffect(() =>{
         
        if(bookWorkspaceState.deskBookingResponse !== undefined && bookWorkspaceState.deskBookingResponse?.bookingId > 0){
            navigate('/bookWSConfirmation');
        }else if(bookWorkspaceState.deskBookingStatus === StateIndicator.Failed && bookWorkspaceState.deskBookingResponse?.bookingId === undefined){
            navigate(`/errortask`);
        }
    }, [bookWorkspaceState.deskBookingStatus]);

    const closeTaskModule = async () => {
        await app.initialize();
        if(bookWorkspaceState.replyToId !== undefined && bookWorkspaceState.initialTimestamp!==undefined){
            redirect();
        }
    }

    const redirect = () => {
        microsoftTeams.initialize();
        const _data = JSON.stringify({ "SSOToken": authState.ssoToken, "GraphTokenOBO": authState.graphToken, "UserCurrentLoginState": mgt.ProviderState.SignedIn.toString(), "ReplyId": bookWorkspaceState.replyToId, "InitialTimestamp": bookWorkspaceState.initialTimestamp })
        const response = { taskComponentName: "ScheduleInitialize", data: _data };
        microsoftTeams.tasks.submitTask(response);
        microsoftTeams.tasks.submitTask();
    }

    const retrieveData = () => {
        appInsights.trackTrace({ message: `${BookWorkspaceAvailability.name}/${retrieveData.name}: Initializing book a workspace`, severityLevel: SeverityLevel.Information, properties: { TenantId: authState.tenantId, UserId: authState.adminApi?.userId, LocationName: selectedLocationName, Dates: selectedDate.current } });
        const wrkspc = [] as WorkspaceType[];
        const groups = [] as Group[];
        const floors = [] as Floor[];
        const personalDefaults = tabViewState.locations.data.personalSpaceDefaults;
        const isDefaultLocSelected = personalDefaults.locationId === selectedLocId;
        const countries = tabViewState?.locations?.data?.countries;
        const selectedLoc: Location | undefined = _.first(countries.flatMap(x => x.regions.flatMap(f => f.locations.filter(x => x.id === selectedLocId))));

        appDispatch(bookWorkspaceActions.setSelectedLocation(selectedLoc));
        if(selectedLoc !== undefined){
            const wrkspace: WorkspaceType[] | undefined = selectedLoc.workspaceTypes.filter(x => x.isWorkspace && x.resourceId === WorkSpaceType.DeskResourceID).sort(x => x.id);
            wrkspc.push(...wrkspace);
            if(wrkspace !== undefined && wrkspace.length > 0){
                groups.push(...wrkspace[0].groups.filter(g => g.makeBooking)
                    .sort(s => isDefaultLocSelected ? (s.id === personalDefaults.groupId ? -1:0) : 0));
                floors.push(...groups[0].floors);
                floors.sort(x => isDefaultLocSelected ? (x.floorNumber === Number(personalDefaults.floor) ? -1:0): 0);
            }
        }

        appDispatch(bookWorkspaceActions.setSelectedDates(selectedDate.current?.split(',')));
        appDispatch(bookWorkspaceActions.setSelectedWSType(_.first(wrkspc)?.id));
        appDispatch(bookWorkspaceActions.setWsTypeDrpData(wrkspc));
        appDispatch(bookWorkspaceActions.setGrpDrpData(groups));
        appDispatch(bookWorkspaceActions.setFloorDrpData(floors));
        appDispatch(bookWorkspaceActions.setSelectedFloor(_.first(floors)?.floorNumber));
        appDispatch(bookWorkspaceActions.setSelectedGroup(_.first(groups)?.id));
    }

    const onWsChange = (e: any) => {
        let modifiedGroups = [] as Group [];
        let modifiedFloors = [] as Floor [];

        modifiedGroups = bookWorkspaceState?.selectedLoc?.workspaceTypes?.filter(w => w.id === Number(e)).flatMap(x => x.groups.filter(g => g.makeBooking))!;
        modifiedFloors = modifiedGroups?.find(g => g.id === _.first(modifiedGroups)?.id)?.floors ?? [];

        appDispatch(bookWorkspaceActions.setSelectedWSType(e));
        appDispatch(bookWorkspaceActions.setGrpDrpData(modifiedGroups));
        appDispatch(bookWorkspaceActions.setFloorDrpData(modifiedFloors));
        appDispatch(bookWorkspaceActions.setSelectedGroup(_.first(modifiedGroups)?.id));
        appDispatch(bookWorkspaceActions.setSelectedFloor(_.first(modifiedFloors)?.floorNumber));
        appInsights.trackEvent({ name: `${BookWorkspaceAvailability.name}/${onWsChange.name}: Changing Workspace`, properties: { TenantId: authState.tenantId, UserId: authState.adminApi?.userId, WorkspaceId: e } });
    }

    const onGroupChange = (e: number) => {
        let modifiedFloors = [] as (Floor[] | undefined);
        modifiedFloors = bookWorkspaceState.selectedLoc?.workspaceTypes?.filter(w => w.id === bookWorkspaceState.selectedWSType)
            ?.flatMap(x => x.groups.filter(x => Number(e) === Number(x.id) && x.makeBooking))?.flatMap(t => t.floors)

        if(modifiedFloors !== undefined){
            appDispatch(bookWorkspaceActions.setFloorDrpData(modifiedFloors));
            appDispatch(bookWorkspaceActions.setSelectedFloor(_.first(modifiedFloors)?.floorNumber));
        }
        appDispatch(bookWorkspaceActions.setSelectedGroup(e));
        appInsights.trackEvent({ name: `${BookWorkspaceAvailability.name}/${onGroupChange.name}: Changing Group`, properties: { TenantId: authState.tenantId, UserId: authState.adminApi?.userId, GroupId: e } });
    }

    const onFloorChange = (e: any) => {
        appDispatch(bookWorkspaceActions.setSelectedFloor(e));
        appInsights.trackEvent({ name: `${BookWorkspaceAvailability.name}/${onFloorChange.name}: Changing Floor`, properties: { TenantId: authState.tenantId, UserId: authState.adminApi?.userId, FloorId: e } });
    }

    const onSpaceChange = (e: any) => {
        appDispatch(bookWorkspaceActions.setSelectedAvailable(e));
        appInsights.trackEvent({ name: `${BookWorkspaceAvailability.name}/${onSpaceChange.name}: Changing Available Space`, properties: { TenantId: authState.tenantId, UserId: authState.adminApi?.userId, AvailableSpaceId: e } });
    }
    
    const prepareDeskAvailabilityReq = () => { 
        const now = moment();       
        const requestDateArray: DateList[] = bookWorkspaceState.selectedDates?.map(dt => { 
            const isToday = moments(dt).locale(i18n.language).isSame(now, "day");
            if(tabViewState.appSettings.deskBookingPeriod === DeskBookingPeriod.Halfday && isToday){
                return todaysSlotGenerator(dt, now);
            }
            return {
                date: dt, 
                bookingType: DeskBookingType.WholeDay
            }
        });
        const req: DeskAvailabilityRequest = {
            userID: authState.adminApi?.userId ?? 0,
            locationID: selectedLocId,
            groupID: bookWorkspaceState.selectedGroup,
            floorList: bookWorkspaceState.selectedFloor ? [bookWorkspaceState.selectedFloor]: [bookWorkspaceState.floorDrpData[0]?.id],
            dateList: requestDateArray,
            workspaceTypeId: bookWorkspaceState.selectedWSType ? bookWorkspaceState.selectedWSType : bookWorkspaceState.wsTypeDrpData[0]?.id
        }
        appInsights.trackTrace({ message: `${BookWorkspaceAvailability.name}/${prepareDeskAvailabilityReq.name}: Preparing Desk availability request`, severityLevel: SeverityLevel.Information, properties: { TenantId: authState.tenantId, UserId: authState.adminApi?.userId, Request: JSON.stringify(req) } });
        return req;
    }

    const bookDesk = () => {
        const now = moment();
        let datesList: any[] = bookWorkspaceState.selectedDates?.map((dt) => {
            const isToday = moments(dt).locale(i18n.language).isSame(now, "day");
            if(tabViewState.appSettings.deskBookingPeriod === DeskBookingPeriod.Halfday && isToday){
                return todaysSlotGenerator(dt, now);
            }
            return {
                date: dt, 
                bookingType: DeskBookingType.WholeDay
            }
        });

        const req : BookDesk = { userId: authState.adminApi?.userId ?? 0, deskId: bookWorkspaceState.selectedAvailable, isUserSelfCertified: true, dateList: datesList };
        appInsights.trackEvent({ name: `${BookWorkspaceAvailability.name}/${bookDesk.name}: Booking a desk`, properties: { TenantId: authState.tenantId, UserId: authState.adminApi?.userId, BookingRequest: JSON.stringify(req) } });
        appDispatch(bookWorkspaceActions.setReplyToId(bookWorkspaceState.replyToId));
        appDispatch(bookWorkspaceActions.setInitialTimestamp(bookWorkspaceState.initialTimestamp));
        appDispatch(bookWorkspaceActions.setSelectedDeskName(bookWorkspaceState.availableDrpData.find(x => x.id === req.deskId).name));
        appDispatch(bookWorkspaceActions.setSelectedFloorName(bookWorkspaceState.floorDrpData.find(x => x.id === bookWorkspaceState.selectedFloor).name));
        appDispatch(bookWorkspaceActions.setSelectedLocationName(selectedLocationName));
        appDispatch(bookDeskAsync(req));
    }

    const todaysSlotGenerator = (dt: string, now: Moment) => {
        const locationTimezone = bookWorkspaceState.selectedLoc!.timeZoneId;
        const locationTimeNow = moment.tz(now, locationTimezone).format(DateFormats.TwelveHourTime);
        if(locationTimeNow.indexOf("PM") > -1){
            return {
                date: dt,
                bookingType: DeskBookingType.PMOnly
            };
        }

        return {
            date: dt,
            bookingType: DeskBookingType.WholeDay
        };
    }
    const deskAvailabilityText = () => {
        if (bookWorkspaceState?.desks?.availableDesks !== undefined && bookWorkspaceState?.desks?.availableDesks !== null && bookWorkspaceState?.desks?.availableDesks?.length > 0) {
            return (<span>
                <LocationComboBox dropDownData={bookWorkspaceState.availableDrpData} defaultSelection={bookWorkspaceState.selectedAvailable} onLocationChange={onSpaceChange} labelText={t("TAB_36")} />
             </span>) 
        }
        else {
            return (<><Label className={styles.availwslabel}>{t("TAB_36")} </Label><br></br><span> {t('TAB_47')}</span></>)
        }
    }
    const goback = () => {
        appDispatch(bookWorkspaceActions.setUserPresenceStatus(StateIndicator.Idle));
        appDispatch(fetchUserPresenceAsync(tabViewState.userPresenceRequest));
        appDispatch(bookWorkspaceActions.setReplyToId(bookWorkspaceState.replyToId));
        appDispatch(bookWorkspaceActions.setInitialTimestamp(bookWorkspaceState.initialTimestamp));
        navigate('/bookWorkspace');
        };
    const renderScreen = () => {
        if(bookWorkspaceState.wsTypeDrpData?.length > 0 && bookWorkspaceState.availableDesksStatus === StateIndicator.Success){
            return (<Container className={styles.book_workspace_avail} fluid>
                    <p>{ 
                         authState.graphTokenErrorCode===Constant.ConsentNotGrantedCode && 
                         <Col xs="12" className={`${styles["submit-shedule-comp-top-des"]} ${styles["submit-shedule-bkws"]}`}>
                                {t("TAB_43")}<a href="https://www.condecosoftware.com/help/condeco-admin-user/topic/teams-bot" target="_blank">{t("TAB_44")}</a>
                         </Col>
                       }
                    </p>
                <p>{t('TAB_45')} <strong>{htmlSanitizer(selectedLocationName)}.</strong></p>
            <Form className={styles.book_workspace_avail__frm}>
                <FormGroup>
                    <Label>Date(s)</Label>
                    <Col sm={12}>        
                        <ul className={styles.book_workspace_avail__date_pills}>
                                {
                                    bookWorkspaceState.selectedDates.map((d: any, i: number) => <li key={i}>{moments(d).locale(i18n.language).format(DateFormats.DateOnly)}&nbsp;{moments(d).locale(i18n.language).format(DateFormats.DayOnly)}&nbsp;{moments(d).locale(i18n.language).format(DateFormats.MonthOnly)}</li>)
                                }
                        </ul>
                    </Col>
                </FormGroup>

                <FormGroup>
                    <Col sm={12}>                            
                        <LocationComboBox dropDownData={bookWorkspaceState.wsTypeDrpData} defaultSelection={bookWorkspaceState.selectedWSType} onLocationChange={onWsChange} labelText={t("TAB_33")} />
                    </Col>
                </FormGroup>
                
                <FormGroup>
                    <Col sm={12}>                            
                        <LocationComboBox dropDownData={bookWorkspaceState.grpDrpData} onLocationChange={onGroupChange} defaultSelection={bookWorkspaceState.selectedGroup} labelText={t("TAB_34")} />
                    </Col>
                </FormGroup>
                
                <FormGroup>
                    <Col sm={12}>                            
                        <LocationComboBox dropDownData={bookWorkspaceState.floorDrpData} defaultSelection={bookWorkspaceState.selectedFloor} onLocationChange={onFloorChange} labelText={t("TAB_35")} />
                    </Col>
                </FormGroup>
                
                <FormGroup>
                    <Col sm={12}>                            
                    {deskAvailabilityText()}
                    </Col>
                </FormGroup>
            </Form>
                <Row className={styles.book_workspace_avail__actionRow}>
                    <Col sm={12} className="text-end">
                        {bookWorkspaceState?.desks?.availableDesks !== undefined && bookWorkspaceState?.desks?.availableDesks !== null && bookWorkspaceState?.desks?.availableDesks?.length > 0 ?
                            <div className={`${styles['form-action']} text-end`}>
                                {!hideBackButton && <FluentButton appearance="outline" onClick={goback}>{t('TAB_21')}</FluentButton>}
                                <FluentButton appearance="outline" onClick={bookDesk}>{t('TAB_22')}</FluentButton>
                            </div> : bookWorkspaceState.replyToId !== 'null' && bookWorkspaceState.replyToId !== '' && bookWorkspaceState.initialTimestamp !== 'null' && bookWorkspaceState.initialTimestamp !== ''  ?
                                <div className={`${styles['form-action']} text-end`} >
                                    {!hideBackButton && <FluentButton appearance="outline" onClick={goback}>{t('TAB_21')}</FluentButton>}
                                    <FluentButton appearance="outline" onClick={async () => await closeTaskModule()}>{t('TAB_23')}</FluentButton>
                                </div> : 
                                <div className={`${styles['form-action']} text-end`} >
                                    <FluentButton appearance="outline" onClick={async () => await closeTaskModule()}>{t('TAB_23')}</FluentButton>
                                </div>
                        }  </Col>
                </Row>
        </Container>)
        }
        else {
            return <Loader />;
        }
    }

    return (
        <>
        {renderScreen()}
        </>
    );
}

export default BookWorkspaceAvailability;