import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { AxiosError } from "axios";
import jwt_decode, { JwtPayload } from "jwt-decode";
import moment from "moment";
import { appInsights } from "../config/app-insights";
import httpCommonClients from "../http-common";
import { TokenErrorResponse } from "../models/api/types/AdminApi/TokenErrorResponse";
import { ValidateSSOResponse } from "../models/ValidateSSOResponse";
import { AuthControllerEndpoint } from "../utils/api-url";
import { Constant, StorageKeys } from "../utils/constant";
import FunctionAppEndpoint from "../utils/function-url";

const getGraphToken = async (ssoToken: string) => {
  try {
    const result = await httpCommonClients.authClient.post<string>(
      AuthControllerEndpoint.GraphTokenOBOEndpoint,
      {},
      {}
    );
    if (result.status === 200) {
      return result.data;
    }
  } catch (error: AxiosError | any) {
    let tid="";
    let user="";
    if(ssoToken!==undefined && ssoToken!==null && ssoToken!==""){
      let de:any=jwt_decode(ssoToken);
      tid=de['tid'];
      user=de['oid']
    }
    appInsights.trackException({ exception: { message: error, name: `AuthServices/getGraphToken: Exception` }, severityLevel: SeverityLevel.Error, properties: { TenantId: tid, User: user } });
    throw error?.response?.status;
  }
};

const getBotUserLoginState = async (ssoToken: string) => {
  try {
    const result = await httpCommonClients.authClient.post<string>(
      AuthControllerEndpoint.GetBotUserLoginStateEndpoint,
      {},
      {}
    );
    if (result.status === 200) {
      return result.data;
    }
  } catch (error: AxiosError | any) {
    let tid="";
    let user="";
    if(ssoToken!==undefined && ssoToken!==null && ssoToken!==""){
      let de:any=jwt_decode(ssoToken);
      tid=de['tid'];
      user=de['oid']
    }
    appInsights.trackException({ exception: { message: error, name: `AuthServices/getBotUserLoginState: Exception` }, severityLevel: SeverityLevel.Error, properties: { TenantId: tid, User: user } });
    return false;
  }
};

const updateUserLoginStateInStorage = async (
  userLoginState: any,
  ssoToken: string
) => {
  try {
    const result = await httpCommonClients.authClient.post<string>(
      AuthControllerEndpoint.updateUserLoginStateInStorageEndpoint,
      userLoginState,
      {}
    );
    if (result.status === 200) {
      return result.data;
    }
  } catch (error: AxiosError | any) {
    let tid="";
    let user="";
    if(ssoToken!==undefined && ssoToken!==null && ssoToken!==""){
      let de:any=jwt_decode(ssoToken);
      tid=de['tid'];
      user=de['oid']
    }
    appInsights.trackException({ exception: { message: error, name: `AuthServices/updateUserLoginStateInStorage: Exception` }, severityLevel: SeverityLevel.Error, properties: { TenantId: tid, User: user } });
    return false;
  }
};

const getAdminApiToken = async (
  ssoToken: string,
  email?: string
): Promise<ValidateSSOResponse | undefined> => {
  try {
    httpCommonClients.processorClient.defaults.data = { Email: email };
    const cachedResponse = localStorage.getItem(
      `${StorageKeys.AdminApiInfo}-${email}`
    );
    if (cachedResponse != null) {
      const adminApiInfo: ValidateSSOResponse = JSON.parse(cachedResponse);
      if (cachedResponse.length > 0) {
        const decoded = jwt_decode<JwtPayload>(adminApiInfo.access_Token);
        if (decoded !== undefined) {
          const now = moment().unix() * 1000;
          const isValid = moment(decoded.exp! * 1000).diff(now, "minutes") > 0;
          if (isValid) {
            return JSON.parse(cachedResponse);
          }
        }
      }
    }

    const result =
      await httpCommonClients.processorClient.post<ValidateSSOResponse>(
        FunctionAppEndpoint.ValidateSSOToken
      );
    if (result.status === 200) {
      localStorage.setItem(`${StorageKeys.AdminApiInfo}-${email}`, JSON.stringify(result.data));
      return result.data;
    } else {
      localStorage.removeItem(`${StorageKeys.AdminApiInfo}-${email}`);
      throw result?.status;
    }
  } catch (error: AxiosError | any) {
    const e: TokenErrorResponse = {
      ErrorCode: error?.response?.data?.errorCode,
      ErrorMessage: error?.response?.data?.errorMessage,
      StatusCode: error?.response?.status
    }

    let tid="";
    let user="";
    if(ssoToken!==undefined && ssoToken!==null && ssoToken!==""){
      let de:any=jwt_decode(ssoToken);
      tid=de['tid'];
      user=de['oid']
    }
    appInsights.trackException({ exception: { message: error, name: `AuthServices/getAdminApiToken: Exception` }, severityLevel: SeverityLevel.Error, properties: { TenantId: tid, User: user } });
    throw e;
  }
};

const AuthServices = {
  getGraphToken,
  getBotUserLoginState,
  updateUserLoginStateInStorage,
  getAdminApiToken
};
export default AuthServices;