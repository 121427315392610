import { UserPresenceApiResponse } from "../models/UserPresenceResponse";
import { DeskAvailabilityApiResponse } from "../models/DeskAvailabilityResponse";
import { LocationApiResponse } from "../models/Locations";
import { SetPresence, SetUserPresenceApiResponse } from "../models/SetUserPresence";
import httpCommonClients from "../http-common";
import { AppSettingsApiResponse } from "../models/AppSettingsApiResponse";
import { Constant } from "../utils/constant";
import { BookDesk, BookDeskResponse } from "../models/BookDesk";

const getAllLocations = async (id: any) => {
  return await httpCommonClients.httpClient.get<LocationApiResponse>(`users/${id}/permissiblegeolocations?preview=true`);
};
const setUserPresence = async (data: SetPresence) => {  
  return await httpCommonClients.httpClient.put<SetUserPresenceApiResponse>(`users/setpresence?preview=true`, data)
};

  // fetch userPresence data
const fetchUserPresenceData = async (data: any) => {  
  if (data.memberEmails.length > Constant.FrequentCollaboratorCount) {
      data.memberEmails = data.memberEmails.slice(0, Constant.FrequentCollaboratorCount);
  }
  return await httpCommonClients.httpClient.post<UserPresenceApiResponse>(`users/userpresence?preview=true`, data);
};

// fetch App Setting
const getEntGlobalSetting = async (id: any) => {
  return await httpCommonClients.httpClient.get<AppSettingsApiResponse>(`users/${id}/appsettings?preview=true`);
};

//fetch Available Desks
const getAvailableDesks = async (data: any) => {
    return await httpCommonClients.httpClient.post<DeskAvailabilityApiResponse>(`deskBookings/availableDesks?preview=true`, data);
}

const bookDesk = async (data: BookDesk) => {  
  return await httpCommonClients.httpClient.post<BookDeskResponse>(`deskBookings?preview=true`, data)
};

const MasterDataService = {
    getAllLocations,
    setUserPresence,
    fetchUserPresenceData,
    getEntGlobalSetting,
    getAvailableDesks,
    bookDesk
  };

  export default MasterDataService;