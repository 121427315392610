import { EnhancedStore, MiddlewareArray } from "@reduxjs/toolkit";
import axios from "axios";
import { AnyAction } from "redux";
import { ThunkMiddleware } from "redux-thunk";
import { AuthType, getAdminApiTokenAsync, getAuthTokenAsync } from "./store/auth-slice";
import { BookWorkspaceType } from "./store/book-workspace-slice";
import { GraphType } from "./store/graph-slice";
import { RootState } from "./store/store";
import { TabViewType } from "./store/tab-view-slice";
import axiosRetry from 'axios-retry';
import { Constant } from "./utils/constant";
import { StateIndicator } from "./models/shared/Enums/StateIndicator";


let store: EnhancedStore<{ tabView: TabViewType; auth: AuthType; graph: GraphType, bookWorkspace: BookWorkspaceType }, AnyAction, MiddlewareArray<[ThunkMiddleware<{ tabView: TabViewType; auth: AuthType; graph: GraphType, bookWorkspace: BookWorkspaceType }, AnyAction, undefined>]>>;

export const injectStore = (_store: EnhancedStore<{ tabView: TabViewType; auth: AuthType; graph: GraphType, bookWorkspace: BookWorkspaceType }, AnyAction, MiddlewareArray<[ThunkMiddleware<{ tabView: TabViewType; auth: AuthType; graph: GraphType, bookWorkspace: BookWorkspaceType }, AnyAction, undefined>]>>) => {
  store = _store;
}


const httpClient = axios.create({
  baseURL: "https://condeco-apis-dev.azure-api.net/miniSyncDev/api/V1/",
  headers: {
    "Content-type": "application/json",
    "Ocp-Apim-Subscription-Key": "",
    Authorization: "",
  },
});

axiosRetry(httpClient, {
  retries: Constant.ApiRetryCount,
  retryDelay: (retryCount) => {
    return retryCount * Constant.ApiRetryBackOffInterval;
  },
  retryCondition: (r) => r.response !== undefined
    ? r.response.status === 401 || r.response.status === 502
    : false,
  shouldResetTimeout: true,
  onRetry: async (retryCount, error, requestConfig) => {
    let state: RootState = store.getState();
    if (state.auth.adminApiTokenStatus !== StateIndicator.Loading) {
      store.dispatch(getAuthTokenAsync())
      state = store.getState();
      const email = state.auth.email;
      const ssoToken = state.auth.ssoToken;
      store.dispatch(getAdminApiTokenAsync({ ssoToken, email }));
    }
  },
});

httpClient.interceptors.request.use(
  async (config) => {
    const state: RootState = store.getState();
    const ocpKey = state.auth.adminApi?.ocpKey;
    const token = state.auth.adminApi?.access_Token;
    config.baseURL = state.auth.adminApi?.adminApiUrl;
    config.headers = {
      "Ocp-Apim-Subscription-Key": ocpKey!,
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Content-Security-Policy": "default-src 'self'",
      "X-Frame-Options": "DENY",
      "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
      "X-Content-Type-Options": "nosniff"
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

const processorClient = axios.create({
  baseURL: process.env.REACT_APP_FUNCTION_APP_URL,
});



processorClient.interceptors.request.use(
  async (config) => {
    const state: RootState = store.getState();
    config.headers = {
      Authorization: `Bearer ${state.auth.ssoToken}`,
      "x-functions-key": String(process.env.REACT_APP_ENVIRONMENT_KEY),
      "Content-Type": "application/json",
      "Content-Security-Policy": "default-src 'self'",
      "X-Frame-Options": "DENY",
      "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
      "X-Content-Type-Options": "nosniff"
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

processorClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const state = store.getState();
    const originalReq = error?.response;
    if (originalReq != null && originalReq !== undefined && originalReq.status === 401) {
      await store.dispatch(getAuthTokenAsync()).unwrap();
      processorClient.defaults.headers.common["Authorization"] = `Bearer ${state.auth.ssoToken}`
      return processorClient;
    }
    return Promise.reject(error);
  }
);

const authClient = axios.create({
  baseURL: process.env.REACT_APP_BOT_APP_URL,
});

authClient.interceptors.request.use(
  async (config) => {
    const state: RootState = store.getState();
    config.headers = {
      Authorization: `Bearer ${state.auth.ssoToken}`,
      "Content-Type": "application/json",
      "Content-Security-Policy": "default-src 'self'",
      "X-Frame-Options": "DENY",
      "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
      "X-Content-Type-Options": "nosniff"
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

const httpCommonClients = {
  httpClient,
  processorClient,
  authClient,
};
export default httpCommonClients;
