import { Client } from "@microsoft/microsoft-graph-client";
import {
  AadUserConversationMember,
  Person,
} from "@microsoft/microsoft-graph-types";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GraphAuthProvider } from "../components/graph/graphAuthProvider";
import { StateIndicator } from "../models/shared/Enums/StateIndicator";
import { GraphConstants } from "../utils/constant";
import Extensions from "../utils/extensions";
import { getContextAsync } from "./auth-slice";
import { RootState } from "./store";
import { appInsights} from "../config/app-insights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

export const initializeGraphClientAsync = createAsyncThunk(
  "graph/initializeGraphClient",
  async (graphToken: string, thunkAPI) => {
    try {
      var clientOptions = {
        authProvider: new GraphAuthProvider(graphToken),
      };
      // Initialize the graph client
      return await Client.initWithMiddleware(clientOptions);
    } catch (error :any) {
      appInsights.trackException({ exception:{ message: error, name: `${GraphSlice.name}/${initializeGraphClientAsync.name}: Exception` }, severityLevel: SeverityLevel.Error});
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getFrequentCollaboratorsAsync = createAsyncThunk(
  "graph/getFrequentCollaborators",
  async (graphClient: Client, thunkAPI) => {
    try {
      const ctx = await thunkAPI.dispatch(getContextAsync()).unwrap();
      appInsights.trackTrace({message:"getFrequentCollaboratorsAsync ctx",severityLevel: SeverityLevel.Information, properties: { ctx }});
      const userGuidContains = "19:".concat (ctx?.user !== undefined ? ctx?.user?.id : "" ,"_");
      if (
        ctx.channel === undefined &&
        ctx.team === undefined &&
        (ctx.chat === undefined || ctx.chat?.id.includes(userGuidContains))
      ) {
        var respose = await graphClient
          .api(GraphConstants.PeopleUrlEndpoint)
          .get();
        let message: Person[] = respose.value;

        return message.flatMap((member) => member.scoredEmailAddresses!.map((address) => address.address!));
      } else {
        return [];
      }
    } catch (error :any) {
      appInsights.trackException({ exception:{ message: error, name: `${GraphSlice.name}/${getFrequentCollaboratorsAsync.name}: Exception` }, severityLevel: SeverityLevel.Error});
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getMembersAsync = createAsyncThunk(
  "graph/getMembers",
  async (graphClient: Client, thunkAPI) => {
    try {
      var response: any = [];
      const ctx = await thunkAPI.dispatch(getContextAsync()).unwrap();
      appInsights.trackTrace({ message:"team context", severityLevel: SeverityLevel.Information, properties: { ctx }})
      if (ctx === undefined) {
        appInsights.trackException({ exception:{ message:"undefined team context", name: `${GraphSlice.name}/${getMembersAsync.name}: Exception` }, severityLevel: SeverityLevel.Error});
        return thunkAPI.rejectWithValue("graph: failed to load context");
      }

      const userGuidContains = "19:".concat (ctx?.user !== undefined ? ctx?.user?.id : "" ,"_");
      if (ctx.chat !== undefined && !ctx.chat.id.includes(userGuidContains))  {
        //group chat
        response = await graphClient
          .api(
            Extensions.FormatString(
              GraphConstants.GroupChatMembersUrlEndpoint,
              ctx.chat?.id!
            )
          )
          .get();
      } else if (ctx.channel !== undefined) {
        //teams channel
        response = await graphClient
          .api(
            Extensions.FormatString(
              GraphConstants.TeamMembersUrlEndpoint,
              ctx.team?.groupId!
            )
          )
          .get();
      }
      let message: AadUserConversationMember[] = response.value;
      return message;
    } catch (error :any) {
      appInsights.trackException({ exception:{ message:error, name: `${GraphSlice.name}/${getMembersAsync.name}: Exception` }, severityLevel: SeverityLevel.Error});
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export interface GraphType {
  client: Client;
  frequentCollaborators: string[];
  members: AadUserConversationMember[];
  status: StateIndicator;
  frequentCollaboratorStatus: StateIndicator;
  membersStatus: StateIndicator;
}

const initialState: GraphType = {
  client: {} as Client,
  frequentCollaborators:[],
  members: [] as AadUserConversationMember[],
  status: StateIndicator.Idle,
  frequentCollaboratorStatus: StateIndicator.Idle,
  membersStatus: StateIndicator.Idle
};

const GraphSlice = createSlice({
  name: "graph",
  initialState,
  reducers: {
    resetCollaboratorStatus(state, action){
      state.frequentCollaboratorStatus = action.payload;
    },
    resetMembersStatus(state, action){
      state.membersStatus = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(initializeGraphClientAsync.rejected, (state) => {
        state.status = StateIndicator.Failed;
      })
      .addCase(initializeGraphClientAsync.pending, (state) => {
        state.status = StateIndicator.Loading;
      })
      .addCase(initializeGraphClientAsync.fulfilled, (state, action) => {
        state.status = StateIndicator.Success;
        state.client = action.payload as Client;
      })
      .addCase(getFrequentCollaboratorsAsync.rejected, (state) => {
        state.status = StateIndicator.Failed;
        state.frequentCollaboratorStatus = StateIndicator.Failed;
      })
      .addCase(getFrequentCollaboratorsAsync.pending, (state) => {
        state.status = StateIndicator.Loading;
        state.frequentCollaboratorStatus = StateIndicator.Loading;
      })
      .addCase(getFrequentCollaboratorsAsync.fulfilled, (state, action) => {
        state.status = StateIndicator.Success;
        state.frequentCollaboratorStatus = StateIndicator.Success;
        state.frequentCollaborators = action.payload;
      })
      .addCase(getMembersAsync.rejected, (state) => {
        state.membersStatus = StateIndicator.Failed;
      })
      .addCase(getMembersAsync.pending, (state) => {
        state.membersStatus = StateIndicator.Loading;
      })
      .addCase(getMembersAsync.fulfilled, (state, action) => {
        state.membersStatus = StateIndicator.Success;
        state.members = action.payload;
      });
  },
});
export const getGraphState = (state: RootState) => state.graph;
export const graphActions = GraphSlice.actions;
export default GraphSlice;
