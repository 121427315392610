import { Col, Container, Row, Alert } from "reactstrap";
import { FluentButton } from "../../ui/fluent/fluent-component-wrapper";
import styles from './submit-schedule.module.scss'
import { useEffect, useRef, useState } from "react";
import DataService from "../../../services/DataService";
import { AttendanceTypes, MemberData, UserPresenceApiResponse } from "../../../models/UserPresenceResponse";
import LocationComboBox from '../../ui/fluent/location-combobox'
import DaysSection from "./days-section";
import { FormatedWeekDays } from "../../../models/shared/CommonInterfaces/WeekDays";
import { AttendanceDetail, SetPresence, UserPresenceDates } from "../../../models/SetUserPresence";
import { DateFormats, WorkSpaceType, Constant } from "../../../utils/constant";
import moment, { Moment } from "moment";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserPresenceAsync, getTabViewState, tabViewActions } from "../../../store/tab-view-slice";
import { getAuthState } from "../../../store/auth-slice";
import Loader from "../../ui/fluent/loader";
import useCountryDropdown from "../../../hooks/useCountryDropdown";
import { useNavigate } from "react-router-dom";
import { getGraphState } from "../../../store/graph-slice";
import { StateIndicator } from "../../../models/shared/Enums/StateIndicator";
import { UserPresenceRequest } from "../../../models/api/types/UserPresence/UserPresenceRequest";
import { AppDispatch } from "../../../store/store";
import { appInsights, reactPlugin } from "../../../config/app-insights";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import "../../../i18n/translation";
import { useTranslation } from "react-i18next";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import GetDayList from "../../../utils/DateUtils";
import { bookWorkspaceActions, getBookWorkspaceState, setUserPresenceAsync } from "../../../store/book-workspace-slice";

interface PropData {
    setSubmitDetails: Function;
    setCanBookWS: Function
}

const BookWorkspace = (props: PropData) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const tabViewState = useSelector(getTabViewState);
    const authState = useSelector(getAuthState);
    const graphClientState = useSelector(getGraphState);
    const bookWorkspaceState = useSelector(getBookWorkspaceState);
    const userId = useRef<number>(0);
    const [countryDropdownData, setCountryDropdownData] = useState<any[]>([]);
    const [userData, userPresenceData] = useState<MemberData[]>([]);
    const locationDropdown = useCountryDropdown();
    const [geodata, setDefaultLocation] = useState<number>();
    const [globalSwitchOn, setGlobalSwtichOn] = useState<boolean>();
    const [selectedDates, setSelectedDates] = useState<UserPresenceDates[]>([]);
    const [isPersonalSpaceSet, SetIsPersonalSpaceSet] = useState<boolean>(false);
    const dispatch = useDispatch<AppDispatch>();
    const [isButtonDisabled, SetButtonDisabled] = useState<boolean>(false);
    useEffect(() => {
        const execute = async () => {
            if (Object.keys(tabViewState.locations).length > 0) {
                setCountryDropdownData(locationDropdown?.current);
                let pSpace = tabViewState?.locations?.data?.personalSpaceDefaults?.locationId;
                let mSpace = tabViewState?.locations?.data?.meetingSpaceDefaults?.locationId;
                let defaultLocationId = pSpace > 0 ? pSpace : mSpace > 0 ? mSpace : 0;
                if (bookWorkspaceState.bookWspceSelectedLocId === undefined || bookWorkspaceState.bookWspceSelectedLocId === 0) {
                    dispatch(bookWorkspaceActions.setBookWspceSelectedLocId(Number(defaultLocationId)));
                } else {
                    defaultLocationId = bookWorkspaceState.bookWspceSelectedLocId;
                }
                if (defaultLocationId === 0 && locationDropdown?.current.length > 0) {
                    let firstLocation = locationDropdown.current[0];
                    defaultLocationId = firstLocation.id;
                }
                setDefaultLocation(defaultLocationId);
                setGlobalSwtichOn(tabViewState?.appSettings?.mandateBookingToSetInOffice);
                userId.current = authState.adminApi?.userId ?? 0;
                SetIsPersonalSpaceSet(pSpace > 0 ? true : false)
                if (defaultLocationId === 0) {
                    SetButtonDisabled(true);
                }
                if (defaultLocationId > 0) {
                    if (Object.keys(tabViewState.userPresence).length === 0 && authState.adminApi?.userId && tabViewState?.usePresenceStatus === StateIndicator.Idle
                        && (authState.graphTokenErrorCode===Constant.ConsentNotGrantedCode || graphClientState.frequentCollaboratorStatus === StateIndicator.Success || graphClientState.frequentCollaboratorStatus === StateIndicator.Failed)
                        && (authState.graphTokenErrorCode===Constant.ConsentNotGrantedCode || graphClientState.membersStatus === StateIndicator.Success || graphClientState.membersStatus === StateIndicator.Failed)) {

                        dispatch(fetchUserPresenceAsync(await prepareUserPresenceReq(Number(defaultLocationId))));
                    }
                    if (Object.keys(tabViewState.userPresence).length > 0 && tabViewState.usePresenceStatus !== StateIndicator.Loading) {
                        retrieveData();
                    }
                }
            }
        };
        execute();
    }, [tabViewState.usePresenceStatus, tabViewState.locationStatus, authState?.adminApi?.userId, graphClientState.frequentCollaboratorStatus, graphClientState.membersStatus, authState?.adminApiTokenStatus,authState.graphTokenErrorCode]);

    useEffect(() => {
        if (bookWorkspaceState.userPresenceStatus === StateIndicator.Success) {
            let canBookPSpace = isPersonalSpaceSet; //Condition 1
            if (isPersonalSpaceSet) {
                canBookPSpace = canBookPersonalSpace(bookWorkspaceState.bookWspceSelectedLocId > 0 ? bookWorkspaceState.bookWspceSelectedLocId : 0); // Condition 2
            }
            if (canBookPSpace && !globalSwitchOn && selectedLocationSelfCertification(bookWorkspaceState.bookWspceSelectedLocId > 0 ? bookWorkspaceState.bookWspceSelectedLocId : 0)) { // Condition 3
                canBookPSpace = false;
            }
            let setDetails = [] as any[];
            let userSelectedDates = [] as any[];
            let userUnSelectedDates = [] as any[];
            if (bookWorkspaceState.userAttendance.length > 0) {
                bookWorkspaceState.userAttendance.forEach((el) => {
                    if (el.AttendanceType === AttendanceTypes.PresentInOffice.toString()) {
                        let usersPresent = [] as any[];
                        let members = tabViewState?.userPresence?.data?.dateWiseUserPresence?.filter(x => x.date === el.Date)[0]?.members;
                        let hasBookedDesk = false;
                        if (members !== undefined && members.length > 0) {
                            hasBookedDesk = members.filter(member => member.userId === userId.current &&
                                (member.workspaceName !== undefined && member.workspaceName.length > 0)).length > 0;
                            members.forEach((data) => {
                                var currentUserId = userId.current ?? 0;
                                if (data.userId !== currentUserId && data.attendanceType.toString() === AttendanceTypes.PresentInOffice.toString()) {
                                    usersPresent.push(data.email);
                                }
                            })
                        }
                        userSelectedDates.push({ presenceDate: el.Date, usersList: usersPresent, hasBookedDesk: hasBookedDesk });
                    } else if (el.AttendanceType === AttendanceTypes.ChoosenWFH.toString()) {
                        userUnSelectedDates.push({ presenceDate: el.Date, usersList: [], hasBookedDesk: false });
                    }

                })
            }
            let locationName = getLocationName();
            props.setSubmitDetails(setDetails = ([{ setDay: '', selectedLocationID: bookWorkspaceState.bookWspceSelectedLocId, selectedLocation: locationName, submitDate: userSelectedDates, unSelectedDates: userUnSelectedDates }]));
            props.setCanBookWS(canBookPSpace);
            appInsights.trackEvent({ name: `${BookWorkspace.name}/${setUserPresenceDetails.name}: submitted status`, properties: { TenantId: authState.tenantId, UserId: authState.adminApi?.userId, Dates: userSelectedDates, LocationName: locationName } });
            navigate('/submittedStatus');
        }
    }, [bookWorkspaceState.userPresenceStatus]);

    const bookDesk = async () => {
        let locationName = getLocationName();
        let dates = selectedDates.filter(_ => _.IsPresent === true).map(d => d.Date);
        appInsights.trackEvent({ name: `${BookWorkspace.name}/${bookDesk.name}: Booking a workspace availability`, properties: { TenantId: authState.tenantId, UserId: authState.adminApi?.userId, Datea: dates, LocationName: locationName } });
        navigate(`/bookWSAvailability?date=${dates}&locationId=${bookWorkspaceState.bookWspceSelectedLocId}&locationName=${locationName}`);
    }

    const getLocationName = (): string =>{
        let locName = countryDropdownData.filter((d: any) => {
            return d.id.toString() === bookWorkspaceState.bookWspceSelectedLocId?.toString();
        });
        return locName[0]?.name;
    }

    const setUserPresenceDetails = async () => {
        let attendenceDetail: any[] = [];
        if (bookWorkspaceState.bookWspceSelectedLocId !== undefined && bookWorkspaceState.bookWspceSelectedLocId !== 0) {
            if (selectedDates.length > 0) {
                selectedDates.forEach((el) => {
                    let isDeskBooked = false;
                    if (tabViewState?.userPresence?.data?.dateWiseUserPresence?.length > 0) {
                        let membersData: any[] = tabViewState.userPresence.data?.dateWiseUserPresence?.filter((q: { date: string }) => q.date === el.Date);
                        if (membersData !== undefined && membersData.length > 0 && membersData[0]?.members.length > 0) {
                            let userBookedDeskData: any[] = membersData[0]?.members?.filter((q: { locationId: number; userId: number }) => q.locationId == bookWorkspaceState.bookWspceSelectedLocId && q.userId === authState?.adminApi?.userId);
                            if (userBookedDeskData?.length > 0 && userBookedDeskData[0].workspaceName !== undefined && userBookedDeskData[0].workspaceName !== null
                                && userBookedDeskData[0].workspaceName !== '') {
                                isDeskBooked = true;
                            }
                        }
                    }
                    if (el.IsPresent && !isDeskBooked) {
                        attendenceDetail.push({ Date: el.Date, AttendanceType: AttendanceTypes.PresentInOffice.toString() });
                    } else if (!el.IsPresent && el.LocationId > 0) {
                        attendenceDetail.push({ Date: el.Date, AttendanceType: AttendanceTypes.ChoosenWFH.toString() });
                    }
                })
            }
            if(attendenceDetail?.length > 0){
            let payLoad: SetPresence = { userID: userId.current ?? 0, AttendanceDetails: attendenceDetail, locationId: bookWorkspaceState.bookWspceSelectedLocId};
            appInsights.trackTrace({ message: "Preparing set user presence request", severityLevel: SeverityLevel.Information, properties: { UserId: authState.adminApi?.userId, Request: JSON.stringify(payLoad) } });
            dispatch(bookWorkspaceActions.setUserAttendace(attendenceDetail));
            dispatch(setUserPresenceAsync(payLoad));
            }else{
                appInsights.trackTrace({ message: "No attendance details found", severityLevel: SeverityLevel.Information, properties: { UserId: authState.adminApi?.userId } });
                dispatch(bookWorkspaceActions.setUserPresenceStatus(StateIndicator.Success));
            }
            
        }
    };

    const selectedLocationSelfCertification = (value: number): boolean => {
        let flag = false;
        let location = locationDropdown.current.filter(loc => (loc.id == value && loc.certificate === true));
        flag = location.length > 0 ? true : false;
        return flag;
    }
    const isLocationCertified: boolean = selectedLocationSelfCertification(bookWorkspaceState.bookWspceSelectedLocId)
    const onCheckBoxChecked = (isChecked: boolean, value: string) => {
        if (isChecked) {
            SetButtonDisabled(false);
            if (selectedDates !== undefined && selectedDates.length > 0) {
                const index = selectedDates.findIndex(q => q.Date == value);
                if (index > -1) {
                    selectedDates[index].IsPresent = true;
                } else {
                    selectedDates.push({ Date: value, IsPresent: true, LocationId: 0, IsPresentFromAPI: false });
                }
            } else {
                selectedDates.push({ Date: value, IsPresent: true, LocationId: 0, IsPresentFromAPI: false });
            }
        } else {
            if (selectedDates !== undefined && selectedDates.length > 0) {
                const index = selectedDates.findIndex(q => q.Date == value);
                if (index > -1) {
                    selectedDates[index].IsPresent = false;
                }
                const checkBoxedChecked = selectedDates.filter(q => q.IsPresentFromAPI === true || q.IsPresent === true);
                if (checkBoxedChecked !== undefined && checkBoxedChecked.length > 0) {
                    SetButtonDisabled(false);
                } else {
                    SetButtonDisabled(true);
                }
            } else {
                SetButtonDisabled(true);
            }
        }
        selectedDates.sort((a, b) => {
            return +new Date(a.Date) - +new Date(b.Date)
        })
    }
    const onDrpLocationChange = async (value: any) => {
        dispatch(bookWorkspaceActions.setBookWspceSelectedLocId(Number(value)));
        dispatch(fetchUserPresenceAsync(await prepareUserPresenceReq(Number(value))));
        bindUserPresenceData();
    }

    const canBookPersonalSpace = (value: number): boolean => {
        let flag = false;
        tabViewState.locations.data.countries.map((rgn) => {
            let regions = rgn.regions;
            regions.map((loc) => {
                let locations = loc.locations.filter(x => x.id == value)
                locations.map((wrkspc) => {
                    let workspace = wrkspc.workspaceTypes.filter(w => w.resourceId == WorkSpaceType.DeskResourceID)
                    workspace.map(gp => {
                        let gpdata = gp.groups.filter(g => g.makeBooking);
                        flag = gpdata.length > 0 ? true : false;
                    });
                })
            });
        });
        return flag;
    }
    const retrieveData = async () => {
        bindUserPresenceData();
    };

    const memberEmailIds = tabViewState?.userPresence?.data?.dateWiseUserPresence?.flatMap(data => data.members).map((member) => {
        return member.email;
    });

    const locationTimeZoneId = (value: any) => {
        let locTimzeZone = '';
        if (Number(value) > 0) {

            let locations = tabViewState?.locations?.data?.countries.flatMap(c => c.regions).flatMap(l => l.locations)
            if (locations !== undefined && locations.length > 0) {
                let location = locations.filter(x => x.id == value);
                if (location !== undefined && location.length > 0) {
                    locTimzeZone = location[0].timeZoneId;
                }
            }
        }
        return locTimzeZone;
    }
    const timeZoneId = locationTimeZoneId(bookWorkspaceState.bookWspceSelectedLocId);
    const locationsDays = GetDayList(timeZoneId);
    const formatedDayList = locationsDays.dayList.map((_date: Moment): FormatedWeekDays => {
        const dayString = locationsDays.days[_date.day()];
        const monthString = locationsDays.months[_date.month()];
        return {

            date: _date,
            formatedDate: dayString + ' ' + monthString + ' ' + _date.date(),
            dateString: _date.format(DateFormats.FullDate),
            days: dayString,
            months: monthString,
            singleDate: _date.date(),
            isSelfCertificationLocation: isLocationCertified,
            isGlobalSwitch: globalSwitchOn!,
            currentDate: locationsDays.currentDate.clone().format(DateFormats.FullDate),
            locationId: bookWorkspaceState.bookWspceSelectedLocId
        }
    });

    let dateList: any[] = [];
    formatedDayList.map((data) => {
        dateList.push(data.dateString);
    });

    const prepareUserPresenceReq = async (locationId: number ) => {
        let graphMemberEmails = graphClientState?.members?.length > 0 ? graphClientState?.members?.map(x => x.email ?? "") : [];
        const req: UserPresenceRequest = {
            userId: authState.adminApi?.userId ?? 0,
            locationId: locationId,
            dates: dateList,
            memberEmails: graphMemberEmails?.length > 0 ? graphMemberEmails :
                (graphClientState?.frequentCollaborators?.length > 0
                    ? graphClientState.frequentCollaborators
                    : [])
        }
        dispatch(tabViewActions.setuserPresenceRequest(req));
        return req;
    }

    const bindUserPresenceData = async () => {
        let memberData: MemberData[] = [];
        let presenceDates: UserPresenceDates[] = [];
        if (bookWorkspaceState.bookWspceSelectedLocId !== 0) {
            if (tabViewState?.userPresence?.data?.dateWiseUserPresence?.length > 0) {
                tabViewState.userPresence.data?.dateWiseUserPresence?.map((data: any) => {
                    let membersData: [] = data.members;
                    let userLocationPresenece: any[] = membersData.filter((q: { locationId: number; userId: number; attendanceType: number; }) => q.locationId == bookWorkspaceState.bookWspceSelectedLocId && q.userId === authState?.adminApi?.userId && q.attendanceType.toString() === AttendanceTypes.PresentInOffice.toString());
                    if (membersData.length > 0) {
                        memberData.push(data);
                    }
                    if (userLocationPresenece?.length > 0) {
                        presenceDates.push({ Date: data.date, IsPresent: true, LocationId: bookWorkspaceState.bookWspceSelectedLocId, IsPresentFromAPI: true });
                        setSelectedDates(presenceDates);
                        SetButtonDisabled(false);
                    }
                    else {
                        presenceDates.push({ Date: data.date, IsPresent: false, LocationId: 0, IsPresentFromAPI: false });
                        setSelectedDates(presenceDates);
                        let hasPresenceDate = presenceDates.filter(x => x.IsPresent === true);
                        if (hasPresenceDate !== undefined && hasPresenceDate.length > 0) {
                            SetButtonDisabled(false);
                        }
                        else {
                            SetButtonDisabled(true);
                        }
                    }
                })
                if (memberData !== undefined && memberData.length > 0) {
                    userPresenceData(memberData);
                }
            }
            else {
                SetButtonDisabled(true);
            }
        } else {
            SetButtonDisabled(true);
        }
    };

    const bookSpaceText = () => {
        const currentDate: Moment = moment();
        let isCurrentDate = currentDate.format(DateFormats.FullDate) === locationsDays.startDate.format(DateFormats.FullDate) ? true : false;
        if (isCurrentDate) {
            return (<span>
                {t('TAB_25').replace("#typeOfWeek#", t('TAB_16'))}
            </span>)
        }
        else if (!isCurrentDate) {
            return (<span>
                {t('TAB_25').replace("#typeOfWeek#", t('TAB_17'))}
            </span>)
        }
    }
    const renderscreen = () => {
        if (authState.adminApiTokenError?.StatusCode === 404) {
            return authState.adminApiTokenError.ErrorCode === 1 ? (<div>
                <h6><strong>{t("TAB_68")}</strong></h6>
                <h6>{t("TAB_69")}</h6>
                <h6>{t("TAB_70")}</h6></div>) :
                (<div>
                    <h6><strong>{t("TAB_71")}</strong></h6>
                    <h6>{t("TAB_72")}</h6>
                    <h6>{t("TAB_73")}</h6>
                </div>);
        }

        if (locationDropdown?.current?.length > 0) {
            if (tabViewState.usePresenceStatus === StateIndicator.Success) {
                if (bookWorkspaceState.userPresenceStatus === StateIndicator.Failed || bookWorkspaceState.userPresenceStatus === StateIndicator.Idle) {
                    return (
                        <div id="submitScheduleView" className={`ent-teams-app ${styles['page-submit-schedule']}`}>
                            <header>
                                <Container fluid className="px-0">
                                    <Row>
                                        {
                                            authState.graphTokenErrorCode === Constant.ConsentNotGrantedCode &&
                                            <Col xs="12" className={`${styles["submit-shedule-comp-top-des"]} ${styles["submit-shedule-bkws"]}`}>
                                                {t("TAB_43")}<a href="https://www.condecosoftware.com/help/condeco-admin-user/topic/teams-bot" target="_blank">{t("TAB_44")}</a>
                                            </Col>
                                        }
                                    </Row>
                                    <Row>
                                        <Col xs="12" id="submitScheduleTopMsg" className={`${styles["submit-shedule-comp-top-des"]} ${styles["submit-shedule-bkws"]}`}>
                                            {bookSpaceText()}
                                        </Col>
                                    </Row>
                                </Container>
                            </header>

                            <main>
                                <Container fluid className="px-0">
                                    <Row>
                                        <Col xs="12" className={`${styles['location-block']}`}>
                                            <LocationComboBox dropDownData={locationDropdown.current} defaultSelection={bookWorkspaceState.bookWspceSelectedLocId} onLocationChange={onDrpLocationChange} labelText={t("TAB_13")} />
                                        </Col>
                                        <Col xs="12" className={`${styles['days-checklist-block']}`}>
                                            <DaysSection data={formatedDayList} onCheckBoxChecked={onCheckBoxChecked} presenceUserData={userData} userId={userId.current ?? 0} locationId={bookWorkspaceState.bookWspceSelectedLocId} />
                                        </Col>
                                        <Col xs="12" id="submitScheduleBottomMsg" className={`${styles['bkws-btm-msg']}`}>
                                            {globalSwitchOn ?
                                                <span>
                                                    {t('TAB_27')}
                                                </span>
                                                : <span>{t('TAB_26')}</span>
                                            }
                                        </Col>
                                    </Row>
                                </Container>
                            </main>

                            <footer>
                                <div className={`${styles['form-action']} text-end`}>
                                    {globalSwitchOn && tabViewState.appSettings?.deskBookingAllowed ?
                                        <FluentButton appearance="outline" disabled={isButtonDisabled} onClick={bookDesk}>{t('TAB_11')}</FluentButton>
                                        : <FluentButton disabled={isButtonDisabled} appearance="outline" onClick={setUserPresenceDetails}>{t('TAB_19')}</FluentButton>
                                    }
                                </div>
                            </footer>
                        </div>)
                }
            }
        }
        return (<Loader />)
    }
    return (
        <>
            {renderscreen()}
        </>
    );
}

export default withAITracking(reactPlugin, BookWorkspace, "book-workspace");
