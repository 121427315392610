import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import AuthSlice from "./auth-slice";
import GraphSlice from "./graph-slice";
import TabViewSlice from "./tab-view-slice";
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { GraphConstants } from "../utils/constant";
import BookWorkspaceSlice from "./book-workspace-slice";


export const store = configureStore({
    reducer: {
        tabView: TabViewSlice.reducer,
        auth: AuthSlice.reducer,
        graph: GraphSlice.reducer,
        bookWorkspace: BookWorkspaceSlice.reducer
    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, GraphConstants.GraphClientAction],
        ignoredPaths: ['graph.client'],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;