import { Moment } from "moment";
import { DateFormats } from "./constant";

const FormatString=(str: string, ...val: string[])=>{
  for (let index = 0; index < val.length; index++) {
    str = str.replace(`{${index}}`, val[index]);
  }
  return str;
}

const getDatesOfWeek = (start: Moment) => {
  const datesOfWeek = [start.format(DateFormats.FullDate)];
  for (let i = 1; i < 5; i++) {
    datesOfWeek.push(start.clone().add(i, "day").format(DateFormats.FullDate));
  }
  return datesOfWeek;
};

const Extensions={
    FormatString,
    getDatesOfWeek
};

export default Extensions;
