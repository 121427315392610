import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { Person, PersonCardInteraction, ViewType } from "@microsoft/mgt-react";
import { Moment } from "moment";
import React from "react";
import { Button, ListGroup, ListGroupItem } from "reactstrap";
import _ from "underscore";
import { reactPlugin } from "../../../config/app-insights";
import { AppSettings } from "../../../models/AppSettingsApiResponse";
import { AttendanceTypes } from "../../../models/UserPresenceResponse";
import { FluentCard, FluentButton, FluentDivider } from "../../ui/fluent/fluent-component-wrapper";
import styles from './tab-view-calendar.module.scss';
import  "../../../i18n/translation";
import { useTranslation } from "react-i18next";
import {htmlSanitizer} from "../../../utils/htmlsanitizer" 

export interface ITabViewWeekDayColumnProps {
    date: Moment;
    locationId: number;
    locationName: string;
    resourceName?: string;
    resourceType?: any;
    floor?: string;
    floorNumber?: number;
    people: any[];
    bookingStatus?: any;
    workspaceName?: string;
    attendanceType?: AttendanceTypes;
    appSettings?: AppSettings;
    onBookAWorkspaceButtonClick: Function;
    onSetInOfficeButtonClick: Function;
    onBookingDeleteButtonClick?: Function;
    workSpaceBtnFlag: boolean
    isPersonalDefaultAvailable: boolean
    previousDayFlag: boolean;
}

const TabViewWeekDayColumn: React.FC<ITabViewWeekDayColumnProps> = (props: ITabViewWeekDayColumnProps) => {
    const { t, i18n } = useTranslation();    
    const moment = require("moment/min/moment-with-locales");
    const getPeople = () => {
        if (props.people.length === 0) {
            return (
                <ListGroup className={styles.weekday_col__people_list}>
                    <ListGroupItem>
                        <span><img src="/assets/images/svg/icon-ellipse.svg" alt="" /></span>
                    </ListGroupItem>
                </ListGroup>
            );
        }

        return (
            <ListGroup className={styles.weekday_col__people_list}>
                {
                    props.people.map((p: any, idx: number) => (
                        <ListGroupItem key={idx}>
                            <Person personQuery={p} view={ViewType.oneline} personCardInteraction={PersonCardInteraction.hover} className={styles.weekday_col__person}></Person>
                        </ListGroupItem>
                    ))
                }
            </ListGroup>
        );
    };

    const deleteBookingHandler = () => {
        if (typeof props.onBookingDeleteButtonClick === 'function') {
            // Pass some ref id to perform some action
            props.onBookingDeleteButtonClick(props.locationId);
        }
    };

    const formattedDeskName = () => {
        if(!_.isUndefined(props.workspaceName) && props.workspaceName.length > 0) {        
            let desk = props.workspaceName + ", " + props.floor + " ";
            desk += (props.floor && props.floor !== '' ? `(${props.floorNumber})` : props.floorNumber);
            return (
                <div title={desk} className={`${styles.weekday_col__bkstatus_text} ${styles.weekday_col__bkstatus_text_deskdesc} truncated-ellipsis-text`}>
                    { desk }
                </div>
            );
        } else {
            return "";
        }
    };


    return (
        <div className={`${styles.weekday_col}`}>
            <h2 title={moment(props.date).locale(i18n.language).format('dddd MMMM DD')} className="truncated-ellipsis-text">{moment(props.date).locale(i18n.language).format('dddd')}&nbsp;<span>{moment(props.date).locale(i18n.language).format('MMM DD')}</span></h2>
            <div className={`${styles.weekday_col__bkstatus}`}>
                {
                    props.locationId > 0
                    && !props.previousDayFlag &&
                    <div className={`${styles.weekday_col__bkstatus_text}`}>
                        <div>{t('TAB_6')}</div>
                        <div className="truncated-ellipsis-text"><span><a href="javascript:void(0)" title={htmlSanitizer(props.locationName)} onClick={() => props.onSetInOfficeButtonClick(props.date, props.locationId)}>{htmlSanitizer(props.locationName)}</a></span></div>
                    </div>
                }
                {
                    props.locationId > 0
                    && props.previousDayFlag &&
                    <div className={`${styles.weekday_col__bkstatus_text}`}>
                        <div>{t('TAB_6')}</div>
                        <div className="truncated-ellipsis-text" title={htmlSanitizer(props.locationName)}><span className={styles.previous_day_location}>{htmlSanitizer(props.locationName)}</span></div>
                    </div>
                }
                { formattedDeskName() }
                {
                    props.attendanceType === AttendanceTypes.WorkingFromHome && <div className={`${styles.weekday_col__bkstatus_text}`}>{t('TAB_3')}</div>
                }
                <div hidden={true} className={styles.weekday_col__deldesk}>
                    <div>
                        <span>
                            <Button  onClick={deleteBookingHandler} type="button" size="sm" className="btn btn-light">
                                <img src="/assets/images/svg/delete.svg" alt="" />
                            </Button>
                        </span>
                        <span title={props.resourceName} className={styles.weekday_col__deldesk_label + " truncated-ellipsis-text"}>
                            {props.resourceName}
                        </span>
                    </div>
                </div>                
                {props.attendanceType !== AttendanceTypes.WorkingFromHome && props.locationId === 0 && !props.previousDayFlag &&
                    <div className={styles.weekday_col__action_area}>
                        <FluentButton appearance="outline" onClick={() => props.onSetInOfficeButtonClick(props.date, props.locationId)}>{t('TAB_24')}</FluentButton>
                    </div>}
                {!props.appSettings?.mandateBookingToSetInOffice && props.appSettings?.deskBookingAllowed && props.isPersonalDefaultAvailable &&
                    props.attendanceType === AttendanceTypes.PresentInOffice &&
                    (_.isUndefined(props.workspaceName) || props.workspaceName.length === 0) && !props.workSpaceBtnFlag && !props.previousDayFlag &&
                    <div className={styles.weekday_col__action_area + ' ' + styles.weekday_col__btnWorkspace}>
                        <FluentButton className={styles.weekday_col__btnWorkspace} appearance="outline" onClick={() => props.onBookAWorkspaceButtonClick(props.date, props.locationId, props.locationName)}>{t('TAB_11')}</FluentButton>
                    </div>}
            </div>
            <FluentCard style={{ 'contentVisibility': 'visible', 'contain': 'none' }}>
                <h3>{t('TAB_7')}&nbsp;({props.people.length})</h3>
                <FluentDivider></FluentDivider>
                <div className={styles.weekday_col__teamMember}>
                    {getPeople()}
                </div>
            </FluentCard>
        </div>
    );
};

export default withAITracking(reactPlugin, TabViewWeekDayColumn, "tab-view-weekday-col");