import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { appInsights } from "../config/app-insights";
import { BookDeskResponse } from "../models/BookDesk";
import { DeskAvailabilityApiResponse } from "../models/DeskAvailabilityResponse";
import { Location } from "../models/Locations";
import { SetPresence, SetUserPresenceApiResponse } from "../models/SetUserPresence";
import { StateIndicator } from "../models/shared/Enums/StateIndicator";
import DataService from "../services/DataService";
import { RootState } from "./store";

export interface BookWorkspaceType {
    desks: DeskAvailabilityApiResponse,
    availableDesksStatus: StateIndicator,
    deskBookingResponse: BookDeskResponse,
    deskBookingStatus: StateIndicator,
    selectedDates: string[],
    replyToId: string,
    initialTimestamp:string,
    selectedLocName: string,
    selectedFloorName: string,
    selectedDeskName: string,
    wsTypeDrpData: any[],
    grpDrpData: any[],
    floorDrpData: any[],
    availableDrpData: any[],
    selectedWSType: number,
    selectedGroup: number,
    selectedFloor: number,
    selectedAvailable: number,
    selectedLoc?: Location,
    setUserPresenceResponse?: SetUserPresenceApiResponse,
    userPresenceStatus: StateIndicator,
    userAttendance: any[],
    bookWspceSelectedLocId: number
}

const initialState: BookWorkspaceType = {
    desks: {} as DeskAvailabilityApiResponse,
    availableDesksStatus: StateIndicator.Idle,
    deskBookingResponse: {} as BookDeskResponse,
    deskBookingStatus: StateIndicator.Idle,
    selectedDates: [] as string[],
    replyToId: '',
    initialTimestamp:'',
    selectedLocName: '',
    selectedFloorName: '',
    selectedDeskName: '',
    wsTypeDrpData: [],
    grpDrpData: [],
    floorDrpData: [],
    availableDrpData: [],
    selectedWSType: 0,
    selectedGroup: 0,
    selectedFloor: 0,
    selectedAvailable: 0,
    selectedLoc: {} as Location,
    setUserPresenceResponse: undefined,
    userPresenceStatus: StateIndicator.Idle,
    userAttendance: [],
    bookWspceSelectedLocId: 0
}

export const fetchDeskAvailabilityAsync = createAsyncThunk(
  "bookWorkspace/deskAvailability",
  async (req: any, thunkAPI) => {
    const rootState : RootState = thunkAPI.getState() as RootState;
    try {
      appInsights.trackTrace({ message: `fetchDeskAvailabilityAsync: calling desk availability endpoint`, severityLevel: SeverityLevel.Information, properties: { TenantId: rootState.auth.tenantId, UserId: rootState.auth.adminApi?.userId, Payload: JSON.stringify(req) } });      
      const response = await DataService.getAvailableDesks(req);
      if(response.status === 200){
        return response?.data;
      } else {        
        appInsights.trackException({ exception:{ message: response.statusText, name: `fetchDeskAvailabilityAsync: Error/Failure` }, severityLevel: SeverityLevel.Error, properties: { TenantId: rootState.auth.tenantId, UserId: rootState.auth.adminApi?.userId, Payload: JSON.stringify(req) } });        
        throw thunkAPI.rejectWithValue(response.statusText);
      }
      
    } catch (error : any) {      
      appInsights.trackException({ exception:{ message: error, name: `fetchDeskAvailabilityAsync: Exception` }, severityLevel: SeverityLevel.Error, properties: { TenantId: rootState.auth.tenantId, UserId: rootState.auth.adminApi?.userId, Payload: JSON.stringify(req) } });      
      throw thunkAPI.rejectWithValue(error);
    }
  }
);

export const bookDeskAsync = createAsyncThunk(
  "bookWorkspace/bookDesk",
  async (req: any, thunkAPI) => {
    const rootState : RootState = thunkAPI.getState() as RootState;
    try {
      appInsights.trackTrace({ message: `bookDeskAsync: calling book desk endpoint`, severityLevel: SeverityLevel.Information, properties: { TenantId: rootState.auth.tenantId, UserId: rootState.auth.adminApi?.userId, Payload: JSON.stringify(req) } });      
      const response = await DataService.bookDesk(req);
      if(response.status === 201){
        return response?.data;
      } else {        
        appInsights.trackException({ exception:{ message: response.statusText, name: `bookDeskAsync: Error/Failure` }, severityLevel: SeverityLevel.Error, properties: { TenantId: rootState.auth.tenantId, UserId: rootState.auth.adminApi?.userId, Payload: JSON.stringify(req) } });        
        throw thunkAPI.rejectWithValue(response.statusText);
      }
      
    } catch (error : any) {      
      appInsights.trackException({ exception:{ message: error, name: `bookDeskAsync: Exception` }, severityLevel: SeverityLevel.Error, properties: { TenantId: rootState.auth.tenantId, UserId: rootState.auth.adminApi?.userId, Payload: JSON.stringify(req) } });      
      throw thunkAPI.rejectWithValue(error);
    }
  }
);

export const setUserPresenceAsync = createAsyncThunk(
  "bookWorkspace/setUserPresence",
  async (req: SetPresence, thunkAPI) => {
    const rootState : RootState = thunkAPI.getState() as RootState;
    try {
      appInsights.trackTrace({ message: `setUserPresenceAsync: calling set userpresence endpoint`, severityLevel: SeverityLevel.Information, properties: { TenantId: rootState.auth.tenantId, UserId: rootState.auth.adminApi?.userId, Payload: JSON.stringify(req) } });
      const response = await DataService.setUserPresence(req);
      if(response.status === 200){
        return response?.data;
      }
      else {
        throw thunkAPI.rejectWithValue(response.statusText);
      }
    } catch (error: any) {
      appInsights.trackException({ exception:{ message: error, name: `setUserPresenceAsync: Exception` }, severityLevel: SeverityLevel.Error, properties: { TenantId: rootState.auth.tenantId, UserId: rootState.auth.adminApi?.userId, Payload: JSON.stringify(req) } });
      throw thunkAPI.rejectWithValue(error);
    }
  }
)

const BookWorkspaceSlice = createSlice({
    name: "bookWorkspace",
    initialState,
    reducers: {
      setSelectedDates(state, action){
        state.selectedDates = action.payload;
      },
      setReplyToId(state, action){
        state.replyToId = action.payload;
      },
      setInitialTimestamp(state,action){
        state.initialTimestamp=action.payload;
      },
      setSelectedLocationName(state, action){
        state.selectedLocName = action.payload;
      },
      setSelectedFloorName(state, action){
        state.selectedFloorName = action.payload;
      },
      setSelectedDeskName(state, action){
        state.selectedDeskName = action.payload;
      },
      setWsTypeDrpData(state, action){
        state.wsTypeDrpData = action.payload.map((x:any) => {return {id: x.id, name: x.name}});
      },
      setGrpDrpData(state, action){
        state.grpDrpData = action.payload.map((x:any) => {return {id: x.id, name: x.name}});
      },
      setFloorDrpData(state, action){
        state.floorDrpData = action.payload.map((x:any) => {return {id: x.floorNumber, name: x.name !== undefined && x.name.length > 0 ? `${x.name} (${x.floorNumber})`: x.floorNumber}});
      },
      setAvailableDrpData(state, action){
        state.availableDrpData = action.payload;
      },
      setSelectedWSType(state, action){
        state.selectedWSType = Number(action.payload);
      },
      setSelectedGroup(state, action){
        state.selectedGroup = Number(action.payload);
      },
      setSelectedFloor(state, action){
        state.selectedFloor = Number(action.payload);
      },
      setSelectedAvailable(state, action){
        state.selectedAvailable = Number(action.payload);
      },
      setAvailableDesksStatus(state, action){
        state.availableDesksStatus = action.payload;
      },
      setSelectedLocation(state, action){
        state.selectedLoc = action.payload;
      },
      setDeskBookingStatus(state, action){
        state.deskBookingStatus = action.payload;
      },
      setUserAttendace(state, action){
        state.userAttendance = action.payload;
      },
      setBookWspceSelectedLocId(state, action){
        state.bookWspceSelectedLocId = action.payload;
      },
      setUserPresenceStatus(state, action) {
        state.userPresenceStatus = action.payload;
      }
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchDeskAvailabilityAsync.pending, state => {
            state.availableDesksStatus = StateIndicator.Loading
        })
        .addCase(fetchDeskAvailabilityAsync.fulfilled, (state, action) => {
            state.availableDesksStatus = StateIndicator.Success;
            if(action.payload !== undefined){
                state.desks = action.payload
            } else {
                state.desks = {} as DeskAvailabilityApiResponse;
            }
        })
        .addCase(fetchDeskAvailabilityAsync.rejected, state => {
            state.availableDesksStatus = StateIndicator.Failed;
        })
        .addCase(bookDeskAsync.pending, state => {
          state.deskBookingStatus = StateIndicator.Loading;
        })
        .addCase(bookDeskAsync.fulfilled, (state, action) => {
          state.deskBookingStatus = StateIndicator.Success;
          if(action.payload !== undefined){
            state.deskBookingResponse = action.payload;
          }
        })
        .addCase(bookDeskAsync.rejected, state => {
          state.deskBookingStatus = StateIndicator.Failed;
        })
        .addCase(setUserPresenceAsync.pending, state => {
          state.userPresenceStatus = StateIndicator.Loading
        })
        .addCase(setUserPresenceAsync.fulfilled, (state, action) => {
          state.userPresenceStatus = StateIndicator.Success;
          state.setUserPresenceResponse = action.payload;
        })
        .addCase(setUserPresenceAsync.rejected, state => {
          state.userPresenceStatus = StateIndicator.Failed;
        })
    }
});

export const bookWorkspaceActions = BookWorkspaceSlice.actions;
export const getBookWorkspaceState = (state: RootState) => state.bookWorkspace;
export default BookWorkspaceSlice;