import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  authActions,
  getAdminApiTokenAsync,
  getAuthState,
  getAuthTokenAsync,
  getGraphTokenAsync,
} from "../../store/auth-slice";
import { AppDispatch } from "../../store/store";
import TabViewCalendar from "../pages/tab-view-calendar/tab-view-calendar";
import * as mgt from "@microsoft/mgt-element";
import AuthServices from "../../services/AuthServices";
import {
  fetchEntGlobalSettingAsync,
  fetchLocationsAsync,
  getTabViewState,
} from "../../store/tab-view-slice";
import Loader from "../ui/fluent/loader";
import { getFrequentCollaboratorsAsync, getGraphState, getMembersAsync, initializeGraphClientAsync } from "../../store/graph-slice";
import { StateIndicator } from "../../models/shared/Enums/StateIndicator";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { appInsights } from "../../config/app-insights";

interface IProps {
  children: any;
}

const SSOLogin = (props: IProps) => {
  const {
    ssoToken,
    email,
    status,
    tenantId,
    graphToken,
    adminApi,
    userGuid,
    userState,
    error,
  } = useSelector(getAuthState);
  const tabViewState = useSelector(getTabViewState);
  const graphClientState = useSelector(getGraphState);
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(getAuthTokenAsync());
  }, []);

  useEffect(() => {
    if (
      ssoToken !== "" &&
      ssoToken !== undefined &&
      email !== "" &&
      email !== undefined &&
      tenantId !== undefined &&
      tenantId !== ""
    ) {
      const req = { ssoToken, email, tenantId };
      dispatch(getAdminApiTokenAsync(req));
      dispatch(getGraphTokenAsync(req));
    }
  }, [ssoToken, email, tenantId]);

  useEffect(() => {
    if (tenantId !== "" && userGuid !== "" && graphToken !== "" && ssoToken !== "") {
      initializeGraphToolKitProvider()
        .then(async () => {
          const loginStateResponse = await getBotUserLoginState();
          if (loginStateResponse) {
            dispatch(authActions.setLoginState(true));
            dispatch(authActions.setUserState(true));
          } else {
            dispatch(authActions.setLoginState(false));
            const updateResponse = await userLogIn();
            if (updateResponse === "true") {
              dispatch(authActions.setLoginState(true));
              dispatch(authActions.setUserState(true));
            }
          }
        })
        .catch((err) => {
          appInsights.trackException({ exception: err, severityLevel: SeverityLevel.Error, properties: { TenantId: tenantId, UserGuid: userGuid } });
          dispatch(authActions.setError(true));
        });
    }
  }, [graphToken, tenantId, userGuid, ssoToken]);

  useEffect(() => {
    const execute = async () => {
      if (adminApi?.userId) {

        if (Object.keys(tabViewState.appSettings).length === 0) {
          await dispatch(fetchEntGlobalSettingAsync(adminApi?.userId));
        }

        if (Object.keys(tabViewState?.locations).length === 0) {
          await dispatch(fetchLocationsAsync(adminApi?.userId));
        }
      }
    };
    execute();
  }, [dispatch, adminApi?.userId]);


  useEffect(() => {
    const execute = async () => {
      if (graphToken !== "") {
        await dispatch(initializeGraphClientAsync(graphToken));
      }
    };
    execute();
  }, [dispatch, graphToken]);


  useEffect(() => {
    const execute = async () => {
      if (Object.keys(graphClientState.client).length !== 0) {

        if (Object.keys(graphClientState.frequentCollaborators).length === 0) {
          await dispatch(getFrequentCollaboratorsAsync(graphClientState.client));
        }
        if (Object.keys(graphClientState.members).length === 0) {
          await dispatch(getMembersAsync(graphClientState.client))
        }
      }
    };
    execute();
  }, [dispatch, graphClientState.client]);


  const initializeGraphToolKitProvider = async () => {
    if (graphToken !== undefined && graphToken !== null && graphToken !== "") {
      appInsights.trackTrace({ message: "Initializing graph toolkit provider", severityLevel: SeverityLevel.Information, properties: { TenantId: tenantId, UserGuid: userGuid } });
    }
    mgt.Providers.globalProvider = new mgt.SimpleProvider(
      () =>
        new Promise((resolve, reject) => {
          resolve(graphToken);
        })
    );
    mgt.Providers.globalProvider.setState(mgt.ProviderState.SignedIn);
  };

  const getBotUserLoginState = async () => {
    const userLoginState = await AuthServices.getBotUserLoginState(
      ssoToken!
    );
    return userLoginState;
  };

  const userLogIn = async () => {
    const updateRes = await AuthServices.updateUserLoginStateInStorage(
      mgt.ProviderState.SignedIn,
      ssoToken!
    );
    if (updateRes) {
      mgt.Providers.globalProvider.setState(mgt.ProviderState.SignedIn);  
    }
    return updateRes;
  };

  return (
    <>
      {(error && !userState) || status === StateIndicator.Loading || status === StateIndicator.Idle ? (
        <Loader />
      ) : (
        props.children || <TabViewCalendar />
      )}
    </>
  );
};

export default SSOLogin;
