import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { appInsights } from "../config/app-insights";
import { UserPresenceRequest } from "../models/api/types/UserPresence/UserPresenceRequest";
import { AppSettings } from "../models/AppSettingsApiResponse";
import { LocationApiResponse } from "../models/Locations";
import { StateIndicator } from "../models/shared/Enums/StateIndicator";
import { UserPresenceApiResponse } from "../models/UserPresenceResponse";
import MasterDataService from "../services/DataService";
import { RootState } from "../store/store";


export const fetchUserPresenceAsync = createAsyncThunk("tabView/userPresence", async (data: UserPresenceRequest, thunkAPI) => {
    const rootState : RootState = thunkAPI.getState() as RootState;
    try {
        appInsights.trackTrace({ message: `fetchUserPresenceAsync: calling userpresence endpoint`, severityLevel: SeverityLevel.Information, properties: { TenantId: rootState.auth.tenantId, UserId: rootState.auth.adminApi?.userId, Payload: JSON.stringify(data) } });
        const response = await MasterDataService.fetchUserPresenceData(data);
        if(response.status === 200){
            return response?.data;
        }
        else{
            appInsights.trackException({ exception:{ message: response.statusText, name: `fetchUserPresenceAsync: Error/Failure` }, severityLevel: SeverityLevel.Error, properties: { TenantId: rootState.auth.tenantId, UserId: rootState.auth.adminApi?.userId, Payload: JSON.stringify(data) } });        
            thunkAPI.rejectWithValue(response.statusText);
        }
    } catch (error : any) {
        appInsights.trackException({ exception:{ message: error, name: `fetchUserPresenceAsync: Exception` }, severityLevel: SeverityLevel.Error, properties: { TenantId: rootState.auth.tenantId, UserId: rootState.auth.adminApi?.userId, Payload: JSON.stringify(data) } });
        return thunkAPI.rejectWithValue(error);
    }    
});

export const fetchEntGlobalSettingAsync = createAsyncThunk("tabView/appSettings", async (userId: number, thunkAPI) => {
    const rootState : RootState = thunkAPI.getState() as RootState;
    try {
        appInsights.trackTrace({ message: `fetchEntGlobalSettingAsync: calling globalsetting endpoint`, severityLevel: SeverityLevel.Information, properties: { TenantId: rootState.auth.tenantId, UserId: rootState.auth.adminApi?.userId } });
        const response = await MasterDataService.getEntGlobalSetting(userId);        
        if(response.status === 200){
            return response?.data.data;
        }
        else{
            appInsights.trackException({ exception:{ message: response.statusText, name: `fetchEntGlobalSettingAsync: Error/Failure` }, severityLevel: SeverityLevel.Error, properties: { TenantId: rootState.auth.tenantId, UserId: rootState.auth.adminApi?.userId } });        
            thunkAPI.rejectWithValue(response.statusText);
        }
    } catch (error : any) {
        appInsights.trackException({ exception:{ message: error, name: `fetchEntGlobalSettingAsync: Exception` }, severityLevel: SeverityLevel.Error, properties: { TenantId: rootState.auth.tenantId, UserId: rootState.auth.adminApi?.userId } });
        return thunkAPI.rejectWithValue(error);
    }
});

export const fetchLocationsAsync = createAsyncThunk("tabView/locations", async (userId : number, thunkAPI) => {
    const rootState : RootState = thunkAPI.getState() as RootState;
    try {
        appInsights.trackTrace({ message: `fetchLocationsAsync: calling locations endpoint`, severityLevel: SeverityLevel.Information, properties: { TenantId: rootState.auth.tenantId, UserId: rootState.auth.adminApi?.userId } });
        const response = await MasterDataService.getAllLocations(userId);
        if(response.status === 200){
            return response?.data;
        }
        else{
            appInsights.trackException({ exception:{ message: response.statusText, name: `fetchLocationsAsync: Error/Failure` }, severityLevel: SeverityLevel.Error, properties: { TenantId: rootState.auth.tenantId, UserId: rootState.auth.adminApi?.userId } });        
            thunkAPI.rejectWithValue(response.statusText);
        }
    } catch (error : any) {
        appInsights.trackException({ exception:{ message: error, name: `fetchLocationsAsync: Exception` }, severityLevel: SeverityLevel.Error, properties: { TenantId: rootState.auth.tenantId, UserId: rootState.auth.adminApi?.userId } });
        return thunkAPI.rejectWithValue(error);
    }
})




export interface TabViewType {
    locations: LocationApiResponse,
    userPresence: UserPresenceApiResponse,
    appSettings: AppSettings,
    status: StateIndicator,
    usePresenceStatus: StateIndicator,
    locationStatus: StateIndicator,
    appSettingsStatus: StateIndicator,
    calStartDate?: string,
    caleEndDate?: string,
    userPresenceRequest: UserPresenceRequest
}

const initialState: TabViewType = {
    locations: {} as LocationApiResponse,
    userPresence: {} as UserPresenceApiResponse,
    appSettings: {} as AppSettings,
    status: StateIndicator.Idle,
    usePresenceStatus: StateIndicator.Idle,
    locationStatus: StateIndicator.Idle,
    appSettingsStatus: StateIndicator.Idle,
    calStartDate: undefined,
    caleEndDate: undefined,
    userPresenceRequest: {} as UserPresenceRequest
}




const TabViewSlice = createSlice({
    name: "tabView",
    initialState,
    reducers:{
        setCalStartDate(state, action) {
            state.calStartDate = action.payload;
        },
        setCalEndDate(state, action) {
            state.caleEndDate = action.payload;
        },
        setuserPresenceRequest(state, action) {
            state.userPresenceRequest = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserPresenceAsync.pending, (state) => {
            state.usePresenceStatus = StateIndicator.Loading
        })
        .addCase(fetchUserPresenceAsync.fulfilled, (state, action) => {
            if(action.payload !== undefined){
                state.usePresenceStatus = StateIndicator.Success;
                state.userPresence = action.payload;
            } else {
                state.usePresenceStatus = StateIndicator.Failed;
                state.userPresence = {} as UserPresenceApiResponse;
            }
        })
        .addCase(fetchUserPresenceAsync.rejected, (state) => {
            state.usePresenceStatus = StateIndicator.Failed;
        })
        .addCase(fetchEntGlobalSettingAsync.pending, state => {
            state.appSettingsStatus = StateIndicator.Loading;
        })
        .addCase(fetchEntGlobalSettingAsync.fulfilled, (state, action) => {
            if(action.payload !== undefined){
                state.appSettingsStatus = StateIndicator.Success;
                state.appSettings = action.payload;
            } else {
                state.appSettingsStatus = StateIndicator.Failed;
            }
        })
        .addCase(fetchEntGlobalSettingAsync.rejected, state => {
            state.appSettingsStatus = StateIndicator.Failed;
        })
        .addCase(fetchLocationsAsync.pending, state => {
            state.locationStatus = StateIndicator.Loading;
        })
        .addCase(fetchLocationsAsync.fulfilled, (state, action) => {
            if(action.payload !== undefined){
                state.locationStatus = StateIndicator.Success;
                state.locations = action.payload;
            } else {
                state.locationStatus = StateIndicator.Failed;
                state.locations = {} as LocationApiResponse;
            }
        })
        .addCase(fetchLocationsAsync.rejected, state => {
            state.locationStatus = StateIndicator.Failed;
        })
    }
});

export const tabViewActions = TabViewSlice.actions;
export const getTabViewState = (state: RootState) => state.tabView;
export default TabViewSlice;