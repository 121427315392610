import { useEffect, useRef } from "react";
import { useSelector } from "react-redux"
import { Country } from "../models/Locations";
import { getTabViewState } from "../store/tab-view-slice"

const useCountryDropdown = () => {
    const tabViewState = useSelector(getTabViewState);
    const countries = useRef<Country[]>();
    const globalSwitch = useRef<boolean>();
    const dropdownData = useRef<any[]>([]);

    useEffect(() => {
        dropDownMemo();
    }, [tabViewState]);

    const dropDownMemo = () => {
        if(tabViewState?.locations?.data?.countries.length > 0 && Object.keys(tabViewState.appSettings).length > 0){
            globalSwitch.current = tabViewState.appSettings.mandateBookingToSetInOffice;
            countries.current = tabViewState?.locations?.data?.countries;
            countries.current.map((rgn) => {
                let regions = rgn.regions;
                regions.map((loc) => {
                    let locations = loc.locations.slice().sort((a:any,b:any) => a.name.localeCompare(b.name));
                    locations.map((wrkspc) => {               
                        wrkspc.workspaceTypes.map((grp) => {
                            const permittedGroup = [] as any;
                                globalSwitch ?
                                permittedGroup.push(grp.groups.filter((grp) => grp.makeBooking)):
                                permittedGroup.push(grp.groups);

                                let existAny = dropdownData.current.filter(e => e.id === wrkspc.id);

                                if((permittedGroup.length > 0) && (existAny.length === 0)){
                                    dropdownData.current.push({id: wrkspc.id, name: wrkspc.name + ', ' + rgn.name, certificate: wrkspc.selfCertification});
                                }                         
                        });               
                    });
                });
            });
        }
    }

    return dropdownData;
}

export default useCountryDropdown;