import { Col, Container, Row } from "reactstrap";
import { FluentSpinner } from "./fluent-component-wrapper";

const Loader = () => {
    return(
        <Container>
            <Col style={{ marginTop: "200px" }} sm={{ offset: 5, size: "auto" }}>
                <Row sm={3}>
                    <FluentSpinner />
                </Row>
            </Col>
        </Container>
    )
}

export default Loader;