import { FluentButton } from "../../ui/fluent/fluent-component-wrapper";
import { app,pages } from "newTeamsjs";
import { ConfigTabConstants, Constant } from "../../../utils/constant";
import { reactPlugin } from "../../../config/app-insights";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { useEffect, useState} from "react";
import styles from "./configuration.module.scss";
import  "../../../i18n/translation";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getGraphState } from "../../../store/graph-slice";
import { StateIndicator } from "../../../models/shared/Enums/StateIndicator";
import Loader from "../../ui/fluent/loader";
import { getAuthState } from "../../../store/auth-slice";

const Configuration = () => {
    const graphClientState = useSelector(getGraphState);
    const {graphToken}= useSelector(getAuthState);
    const {t} = useTranslation();
    const [showLoader,setShowLoader]=useState<boolean>(true);
    const saveRed = () => {
        setShowLoader(false);
        pages.config.registerOnSaveHandler((saveEvent) => {
            pages.config.setConfig({
                websiteUrl: `${process.env.REACT_APP_TAB_URL}`,
                contentUrl: `${process.env.REACT_APP_TAB_URL}`,
                entityId: ConfigTabConstants.EntityId,
                suggestedDisplayName: ConfigTabConstants.TabName,
            });
            saveEvent.notifySuccess();
        });
    }
    useEffect(() => {
        if(graphToken===""||graphClientState.membersStatus===StateIndicator.Success ||graphClientState.membersStatus===StateIndicator.Failed)
        {
          app.initialize();
          pages.config.setValidityState(true);
          saveRed();
        }
    },[graphClientState.membersStatus,graphToken]);

    return (
        <>
        {showLoader?(<Loader/>):(<div className={styles.configuration_view + " d-flex align-items-center justify-content-center"}><div className="text-center">
        <img className={styles.imgbookingdesk} src="assets/images/bookingdesk.png"></img>
        <p className={styles.imgbookingdesk_welcome_msg + " mb-0"}>{t('TAB_41')}</p>
        <p className={styles.imgbookingdesk_welcome_msg2 + " mb-0"}>{t('TAB_37')}</p>
        <p className={styles.imgbookingdesk_welcome_msg2 + " mb-0"}>{t('TAB_42')} <a href="https://www.condecosoftware.com/help/condeco-admin-user/topic/teams-bot" target="_blank">{t("TAB_44")}</a></p>        
      </div></div>)}
      </>    
    );
};

export default withAITracking(reactPlugin, Configuration, "configuration");