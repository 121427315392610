export interface UserPresenceApiResponse{
    isError: boolean;
    data:    SpecificDateWiseUserPresenceData;
    status:  boolean;
    message: string;
}

export interface SpecificDateWiseUserPresenceData {
    dateWiseUserPresence: MemberData[];
    invalidUsers:         string[];
}

export interface MemberData {
    date:string;
    members: Members[];
}

export enum AttendanceTypes {
    PresentInOffice = 0,
    WorkingFromHome = 1, //forced(mandated)
    OnLeave = 2,
    Absent = 3,
    ChoosenWFH = 4, // self chosen/default
    Unknown = 5
}

export interface Members {
    userId:number;
    name: string;
    email: string;
    teamId: number;
    attendanceType: AttendanceTypes;
    locationId:number;
    locationName: string;
    floorName:string;
    floorNumber: number;
    workspaceName: string;
}