import { People, PersonCardInteraction } from "@microsoft/mgt-react";
import { FluentCheckbox } from "../../ui/fluent/fluent-component-wrapper";
import { Col, ListGroup, ListGroupItem, Row } from "reactstrap";
import { useState } from "react";
import { AttendanceTypes } from "../../../models/UserPresenceResponse";
import { Constant } from "../../../utils/constant";
import { reactPlugin } from "../../../config/app-insights";
import { withAITracking } from "@microsoft/applicationinsights-react-js";

interface WeekDays {
    data: any[];
    onCheckBoxChecked: Function;
    presenceUserData: any[];
    userId: number;
    locationId: number;
}

const DaysSection: React.FC<WeekDays> = (props: WeekDays) => {
    let presentDates = [] as any[];
    const [datesCheckedStatus, setdatesCheckedStatus] = useState<any[]>([]);
    //getUserAttendance data 
    const getUserAttendance = (date: string) => {
        let isCheckboxChecked: boolean = false;
        let index = datesCheckedStatus.findIndex(q => q.locationId !== props.locationId);
        if (index > -1) {
            setdatesCheckedStatus([]);
        }
        if (props.presenceUserData !== null && props.presenceUserData !== undefined && props.presenceUserData.length > 0) {
            let data: any[] = props.presenceUserData.filter(p => p.date === date);
            if (data !== null && data !== undefined && data.length > 0) {
                let membersData = data[0].members.filter((q: { locationId: number; attendanceType: number; userId: number }) => q.locationId.toString() === props.locationId.toString() && q.userId == props.userId && q.attendanceType.toString() === AttendanceTypes.PresentInOffice.toString());
                if (membersData !== null && membersData !== undefined && membersData.length > 0) {
                    isCheckboxChecked = true;
                }
            }
        }
        if (datesCheckedStatus !== undefined && datesCheckedStatus.length > 0) {
            let isChecked = datesCheckedStatus.filter(q => q.presensedate === date && q.isChecked === true && q.locationId === props.locationId)
            if (isChecked !== undefined && isChecked.length > 0) {
                isCheckboxChecked = true;
            } 
        }
        return isCheckboxChecked;
    }

    const disableCheckbox = (date: string, isGlobalSwitch: boolean, isSelfCertificationLocation: boolean, currentDate: string) => {
       let IsDisabled:boolean = false;
        if (isGlobalSwitch && isSelfCertificationLocation) {
            if (date === currentDate) {
                IsDisabled = true;
            }else {
                IsDisabled = false;
            }
        }
        //Disabling checkboxes for the dates user is madated to WFH
        if(props.presenceUserData !== null && props.presenceUserData !== undefined && props.presenceUserData.length > 0){
            let data: any[] = props.presenceUserData.filter(p => p.date === date);
            if (data !== null && data !== undefined && data.length > 0) {
                let mandatedToWorkRemotely = data[0].members.filter((q: {attendanceType: number; userId: number }) =>
                q.userId == props.userId && q.attendanceType.toString() === AttendanceTypes.WorkingFromHome.toString());
                if (mandatedToWorkRemotely !== null && mandatedToWorkRemotely !== undefined && mandatedToWorkRemotely.length > 0) {
                    IsDisabled = true;
                }
            }
        }
        return IsDisabled;
    }
    //getUserEmails data 
    const getUserEmails = (date: string) => {
        let userEmailNew: string[] = [];
        let data: any[] = props.presenceUserData.filter(p => p.date === date);
        if (data !== null && data !== undefined && data.length > 0) {
            let membersData = data[0].members.filter((q: { locationId: number; attendanceType: number, userId: number }) => q.locationId.toString() === props.locationId.toString() && q.userId !== props.userId && q.attendanceType.toString() === AttendanceTypes.PresentInOffice.toString());
            if (membersData.length > 0) {
                membersData.map((md: any) => {
                    userEmailNew.push(md.email);
                });
            }
            return userEmailNew;
        }
        return userEmailNew;
}
    const checkedDates = (e: any) => {
        presentDates = datesCheckedStatus;
        if(e.currentTarget.checked){
            presentDates.push({ isChecked: e.currentTarget.checked, presensedate: e.currentTarget.id, locationId: props.locationId });
        }
        else if(!e.currentTarget.checked){
            let index = presentDates.findIndex(q => q.presensedate === e.currentTarget.id && q.locationId === props.locationId);
            if (index > -1) {
                presentDates.splice(index, 1);
            }
        }
        setdatesCheckedStatus(presentDates);
        props.onCheckBoxChecked(e.currentTarget.checked, e.currentTarget.id);        
    }
    return (
        <div className="days day-checkbox-list">
        <div className="font-weight-bold day-list-label">Dates</div>
        <ListGroup flush className="day-list-group">
            {props.data.map((data) => (
                <ListGroupItem className="ps-0 pe-0 bg-transparent border-bottom-0" key={data.dateString}>
                    <div className="align-items-center d-flex">
                        <div className="day-list-col w-50">
                            <FluentCheckbox disabled= {disableCheckbox(data.dateString, data.isGlobalSwitch,data.isSelfCertificationLocation,data.currentDate)} checked={getUserAttendance(data.dateString)} id={data.dateString} onChange={checkedDates}>
                                <div className="fluentcheckboxlabel text-truncate">
                                    <span className="font-weight-bold">{data.days} </span> <span>{data.months} </span> <span>{data.singleDate}</span>
                                </div>
                             </FluentCheckbox>
                        </div>
                        <div className="day-list-col w-50 ps-2">
                            <People className="people-ui" showMax={Constant.ShowMax} userIds={getUserEmails(data.dateString)}></People>
                        </div>
                    </div>
                </ListGroupItem>
            ))
            }
        </ListGroup>
    </div>
    );
}

export default withAITracking(reactPlugin, DaysSection, "days-section");