import React from "react";
import ReactDOM from "react-dom/client";
import "./scss/main.scss";
import reportWebVitals from "./reportWebVitals";
import AppRoute from "./routes/app-route";
import { Provider } from "react-redux";
import { store } from "./store/store";
import App from "./App";
import { injectStore } from "./http-common";

injectStore(store);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
if(process.env.NODE_ENV === "development") {
  root.render(
    /*
     * only for dev purposes
     * strict mdoe should be disabled on prod
     */
    <React.StrictMode>
      <Provider store={store}>
          <App>
            <AppRoute production={false} />
          </App>
      </Provider>
    </React.StrictMode>
  );
} else {
  root.render(
    <React.Fragment>
      <Provider store={store}>
          <App>
            <AppRoute production={true} />
          </App>
      </Provider>
    </React.Fragment>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
