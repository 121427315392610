import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SetStatus from "../components/pages/submit-schedule/set-status";
import SubmitSchedule from "../components/pages/submit-schedule/submit-schedule";
import TabViewCalendar from "../components/pages/tab-view-calendar/tab-view-calendar";
import { useState } from "react";
import SubmittedSchedule from "../components/pages/submit-schedule/submitted-schedule"
import Configuration from "../components/pages/configuration-page/configuration";
import BookWorkspaceAvailability from "../components/pages/book-workspace/book-a-workspace";
import { setLanguage } from "../i18n/translation";
import DeskBooking from "../components/pages/submit-schedule/Booking-Confirmation"
import ErrorTask from "../components/pages/submit-schedule/error-task";
import BookWorkspace from "../components/pages/submit-schedule/book-workspace";

interface IAppRoute {
    production: boolean;
}

const AppRoute: React.FC<IAppRoute> = (props: IAppRoute) => {

    const [submitDetail, setSubmitDetail] = useState<any>([]);
    const[canBookWS,setCanBookWs]=useState<boolean>(false);

    useEffect(()=>{
       setLanguage();
    })

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/submitSchedule" element={<SubmitSchedule setSubmitDetails={setSubmitDetail} setCanBookWS={setCanBookWs} />} />
                <Route path="/" element={<TabViewCalendar />} />
                <Route path="/setStatus" element={<SetStatus setSubmitDetails={setSubmitDetail} setCanBookWS={setCanBookWs} />} />
                <Route path="/bookWSAvailability" element={<BookWorkspaceAvailability/>} />
                <Route path="/submittedStatus" element={<SubmittedSchedule submitDetail = {submitDetail} canBookWS={canBookWS} />} />                
                <Route path="/configure" element={<Configuration />} />
                <Route path="/bookWSConfirmation" element={<DeskBooking/>} />
                <Route path="/errortask" element={<ErrorTask/>} />
                <Route path="/bookWorkspace" element={<BookWorkspace setSubmitDetails={setSubmitDetail} setCanBookWS={setCanBookWs} />} />
            </Routes>
        </BrowserRouter>
    );
}

export default AppRoute;